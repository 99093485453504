import { Box, IconChevron, Heading } from 'braid-design-system';

import { RoundedCard } from '../RoundedCard/RoundedCard';

import * as styles from './accordionSection.css';

export interface AccordionSectionProps {
  children: ReactNodeNoStrings;
  id?: string;
  title: string;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
}

export const AccordionSection = ({
  children,
  id,
  title,
  isExpanded,
  onExpand,
}: AccordionSectionProps) => {
  const handleAccordianHeaderClick = () => {
    onExpand(!isExpanded);
  };

  return (
    <Box id={id}>
      <RoundedCard
        cursor="pointer"
        data-id="ui-accordion-section-button"
        roundTopOnly={isExpanded}
        onClick={handleAccordianHeaderClick}
      >
        <Heading level="3" weight="weak">
          {title}
        </Heading>
        <div className={styles.accordionChevron}>
          <IconChevron direction={isExpanded ? 'up' : 'down'} size="small" />
        </div>
      </RoundedCard>
      {isExpanded && (
        <Box>
          <Box style={{ height: '2px' }} />
          <RoundedCard roundBottomOnly={true}>{children}</RoundedCard>
        </Box>
      )}
    </Box>
  );
};
