import { Box, Divider, Stack } from 'braid-design-system';

import { useHidden } from '../../hooks/useHidden';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MarketPerformance } from '../../widgets/MarketPerformance/MarketPerformance';
import { MarketPerformanceErrorBoundary } from '../../widgets/MarketPerformance/MarketPerformanceErrorBoundary';
import { MostAdsByPerformance } from '../../widgets/MostAdsByPerformance/MostAdsByPerformance';
import { MostAdsByPerformanceErrorBoundary } from '../../widgets/MostAdsByPerformance/MostAdsByPerformanceErrorBoundary';

export const PerformanceTab = () => {
  const isSmallDesktop = useMediaQuery('(max-width: 1500px)');
  const isLargeDesktop = useMediaQuery('(min-width: 1600px)');
  const isCompactView = useHidden([true, true, false, false]);

  const getWidth = () => {
    if (isSmallDesktop)
      return {
        chart: '40%',
        adsPerf: '60%',
      };
    if (isLargeDesktop)
      return {
        chart: '50%',
        adsPerf: '50%',
      };
    return {
      chart: '45%',
      adsPerf: '55%',
    };
  };

  if (isCompactView) {
    return (
      <Stack space="large">
        <MarketPerformanceErrorBoundary>
          <Box width="full">
            <MarketPerformance />
          </Box>
        </MarketPerformanceErrorBoundary>
        <Divider />
        <MostAdsByPerformanceErrorBoundary>
          <Box width="full">
            <MostAdsByPerformance />
          </Box>
        </MostAdsByPerformanceErrorBoundary>
      </Stack>
    );
  }

  return (
    <Box display="flex">
      <MarketPerformanceErrorBoundary>
        <Box style={{ width: getWidth().chart }}>
          <MarketPerformance />
        </Box>
      </MarketPerformanceErrorBoundary>
      <Box minWidth={0}>
        <Box
          background="neutralLight"
          marginRight="large"
          marginLeft="large"
          height="full"
          style={{ width: '1px' }}
        />
      </Box>
      <MostAdsByPerformanceErrorBoundary>
        <Box style={{ width: getWidth().adsPerf }}>
          <MostAdsByPerformance />
        </Box>
      </MostAdsByPerformanceErrorBoundary>
    </Box>
  );
};
