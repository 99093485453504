import {
  createUrlResolver,
  getZoneFromSite,
  siteNames,
  siteConfig,
  createAbsoluteUrl,
} from '@seek/melways-sites';
import { createContext, useContext, useCallback } from 'react';

import type { Config } from '../config';

export const defaultConfig: Config = {
  site: siteNames.employer.seek.au,
  locale: 'en-AU',
  environment: 'development',
  language: 'en',
  isLocal: false,
  version: '',
  isTest: false,
  hotjarId: undefined,
  branch: '',
};

const ConfigContext = createContext<Config>({
  ...defaultConfig,
});

const useConfig = () => {
  const config = useContext(ConfigContext);
  const country = siteConfig[config.site].classification.country;
  const isStaging = config.environment === 'staging';

  const urlResolver = useCallback(
    (path: string) =>
      createUrlResolver({
        site: config.site,
        language: config.language,
        staging: isStaging,
      })({ path, product: 'employer' }),
    [config, isStaging],
  );
  const absoluteUrlResolver = useCallback(
    (path: string) =>
      createAbsoluteUrl({
        product: 'employer',
        language: config.language,
        country,
        path,
        staging: isStaging,
      }),
    [config, country, isStaging],
  );
  return {
    absoluteUrlResolver,
    ...config,
    country,
    urlResolver,
    zone: getZoneFromSite(config.site),
  };
};

export { ConfigContext, useConfig };
