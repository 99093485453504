import { useTranslations } from '@vocab/react';
import { Box, Notice, Text } from 'braid-design-system';

import { useConfig } from '../../App/ConfigContext';
import { LAUNCH_DATES } from '../../featureToggle/config/historicalDataFeature';
import { formatDate } from '../../utils/date';

import translations from './.vocab';

export const TaxMessage = () => {
  const { t } = useTranslations(translations);
  const { site } = useConfig();

  return (
    <Box paddingLeft="gutter" paddingBottom="large">
      <Notice tone="info">
        <Text>
          {LAUNCH_DATES[site]
            ? t(
                "This report doesn't include taxes and ads purchased without an Ad Budget before {launchDate}.",
                {
                  launchDate: formatDate({
                    date: new Date(LAUNCH_DATES[site]),
                  }),
                },
              )
            : t('Costs in this report do not include taxes')}
        </Text>
      </Notice>
    </Box>
  );
};
