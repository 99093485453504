import { type ApolloError, useQuery } from '@apollo/client';

import { candidateSupplyQuery } from '../queries/candidateSupplyQuery';
import type { CandidateSupplyResponse } from '../types/MarketInsight';

interface CandidateSupplyQueryParams {
  input: {
    jobId: string;
  };
}

export const useCandidateSupplyData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: CandidateSupplyResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { data, loading, error, previousData } = useQuery<
    { analyticsCandidateSupply: CandidateSupplyResponse },
    CandidateSupplyQueryParams
  >(candidateSupplyQuery, {
    skip: !jobId,
    variables: {
      input: {
        jobId,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsCandidateSupply,
    isLoading: loading,
    error,
  };
};
