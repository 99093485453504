import { EmployerFooter } from '@seek/adv-header-footer';
import { type HTMLProps, useCallback } from 'react';

import { getEnvironment, getLocale, getSite } from '../../config';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';

export const AdCentreFooter = ({
  adCentreAccountInfo,
}: {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
}) => {
  const linkRenderer = useCallback(
    (origin: string) => (props: HTMLProps<HTMLAnchorElement>) => {
      if (origin) {
        return <a {...props} data-analytics-origin={origin} />;
      }
      return <a {...props} />;
    },
    [],
  );

  const isStaging = getEnvironment() === 'staging';

  return (
    <EmployerFooter
      linkRenderer={linkRenderer('global-header')}
      locale={getLocale()}
      site={getSite()}
      isStaging={isStaging}
      {...adCentreAccountInfo?.headerFooterContext}
    />
  );
};
