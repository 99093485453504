
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiLguYTguKHguYjguKHguLXguITguLPguYHguJnguLDguJnguLPguKrguLPguKvguKPguLHguJrguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJnguLXguYnguYPguKvguYnguJTguLXguILguLbguYnguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiLguYTguKHguYjguKHguLXguITguLPguYHguJnguLDguJnguLPguKrguLPguKvguKPguLHguJrguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJnguLXguYnguYPguKvguYnguJTguLXguILguLbguYnguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJUaWRhayBhZGEgc2FyYW4gdW50dWsgbWVuaW5na2F0a2FuIGt1YWxpdGFzIGxvd29uZ2FuIGtlcmphIGluaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJUaWRhayBhZGEgc2FyYW4gdW50dWsgbWVuaW5na2F0a2FuIGt1YWxpdGFzIGxvd29uZ2FuIGtlcmphIGluaS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiLguYTguKHguYjguKHguLXguITguLPguYHguJnguLDguJnguLPguKrguLPguKvguKPguLHguJrguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJnguLXguYnguYPguKvguYnguJTguLXguILguLbguYnguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiLguYTguKHguYjguKHguLXguITguLPguYHguJnguLDguJnguLPguKrguLPguKvguKPguLHguJrguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJnguLXguYnguYPguKvguYnguJTguLXguILguLbguYnguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJUaWRhayBhZGEgc2FyYW4gdW50dWsgbWVuaW5na2F0a2FuIGt1YWxpdGFzIGxvd29uZ2FuIGtlcmphIGluaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJUaWRhayBhZGEgc2FyYW4gdW50dWsgbWVuaW5na2F0a2FuIGt1YWxpdGFzIGxvd29uZ2FuIGtlcmphIGluaS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJbxbThur3hur3hur0gxozDtsO2w7bguIHguLXguYnigJnhua8g4bipxIPEg8SD4bm94bq94bq94bq9IMSDxIPEg+C4geC4teC5icO9w73DvSDFoceax5rHmsSjxKPhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG5r8O2w7bDtiDDrMOsw6xtzILGpcWZw7bDtsO24bm94bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMSDxIPEg8aMLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb27igJl0IGhhdmUgYW55IHN1Z2dlc3Rpb25zIHRvIGltcHJvdmUgdGhpcyBhZC4iOiJbxbThur3hur3hur0gxozDtsO2w7bguIHguLXguYnigJnhua8g4bipxIPEg8SD4bm94bq94bq94bq9IMSDxIPEg+C4geC4teC5icO9w73DvSDFoceax5rHmsSjxKPhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG5r8O2w7bDtiDDrMOsw6xtzILGpcWZw7bDtsO24bm94bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMSDxIPEg8aMLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    