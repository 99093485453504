import { useTranslations } from '@vocab/react';
import { Stack, Loader } from 'braid-design-system';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AccordionSection } from '../../components/AccordionSection/AccordionSection';
import { MyJobInformation as MyJobInformationComponent } from '../../components/MyJobInformation/MyJobInformation';
import { useMyJobInformationData } from '../../hooks/useMyJobInformationData';
import { trackEvent } from '../../utils/tealiumEventTracker';

import translations from './.vocab';
import { MyJobInformationFallback } from './MyJobInformationFallback';

const sectionId = 'job-summary';

export const MyJobInformation = () => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();

  const {
    data: details,
    error,
    isLoading,
  } = useMyJobInformationData({
    jobId: jobIdParam!,
  });

  const { hash } = window.location;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(
    hash !== null && hash.replace('#', '') === sectionId,
  );

  const onAccordionExpand = (isExpanded: boolean) => {
    if (isExpanded) {
      trackEvent('my_job_info_expand_pressed', {
        objectInteraction: 'apr-section-your-job-ad-expanded',
      });
    }

    setIsAccordionExpanded(isExpanded);
  };

  const accordionProps = {
    id: sectionId,
    isExpanded: isAccordionExpanded,
    title: t('My job information'),
    onExpand: onAccordionExpand,
  };

  if (isLoading && !details) {
    return (
      <AccordionSection {...accordionProps}>
        <Stack align="center" space="large">
          <Loader />
        </Stack>
      </AccordionSection>
    );
  }

  if (error || !details) {
    return <MyJobInformationFallback />;
  }

  return (
    <MyJobInformationComponent
      accordionProps={accordionProps}
      details={details}
    />
  );
};
