
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4mSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiLguIHguLLguKPguYPguIrguYnguIfguLLguJkgVGFsZW50IFNlYXJjaCIsIkRpc2NvdmVyIE1vcmUiOiLguITguYnguJnguKvguLLguYDguJ7guLTguYjguKHguYDguJXguLTguKEifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4mSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiLguIHguLLguKPguYPguIrguYnguIfguLLguJkgVGFsZW50IFNlYXJjaCIsIkRpc2NvdmVyIE1vcmUiOiLguITguYnguJnguKvguLLguYDguJ7guLTguYjguKHguYDguJXguLTguKEifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlBlbmdndW5hYW4gaWtsYW4iLCJUYWxlbnQgU2VhcmNoIFVzYWdlIjoiUGVuZ2d1bmFhbiBwZW5jYXJpYW4gYmFrYXQiLCJEaXNjb3ZlciBNb3JlIjoiVGVtdWthbiBsZWJpaCBiYW55YWsifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlBlbmdndW5hYW4gaWtsYW4iLCJUYWxlbnQgU2VhcmNoIFVzYWdlIjoiUGVuZ2d1bmFhbiBwZW5jYXJpYW4gYmFrYXQiLCJEaXNjb3ZlciBNb3JlIjoiVGVtdWthbiBsZWJpaCBiYW55YWsifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4mSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiLguIHguLLguKPguYPguIrguYnguIfguLLguJkgVGFsZW50IFNlYXJjaCIsIkRpc2NvdmVyIE1vcmUiOiLguITguYnguJnguKvguLLguYDguJ7guLTguYjguKHguYDguJXguLTguKEifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4mSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiLguIHguLLguKPguYPguIrguYnguIfguLLguJkgVGFsZW50IFNlYXJjaCIsIkRpc2NvdmVyIE1vcmUiOiLguITguYnguJnguKvguLLguYDguJ7guLTguYjguKHguYDguJXguLTguKEifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlBlbmdndW5hYW4gaWtsYW4iLCJUYWxlbnQgU2VhcmNoIFVzYWdlIjoiUGVuZ2d1bmFhbiBwZW5jYXJpYW4gYmFrYXQiLCJEaXNjb3ZlciBNb3JlIjoiVGVtdWthbiBsZWJpaCBiYW55YWsifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlBlbmdndW5hYW4gaWtsYW4iLCJUYWxlbnQgU2VhcmNoIFVzYWdlIjoiUGVuZ2d1bmFhbiBwZW5jYXJpYW4gYmFrYXQiLCJEaXNjb3ZlciBNb3JlIjoiVGVtdWthbiBsZWJpaCBiYW55YWsifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IkFkIHVzYWdlIiwiVGFsZW50IFNlYXJjaCBVc2FnZSI6IlRhbGVudCBTZWFyY2ggdXNhZ2UiLCJEaXNjb3ZlciBNb3JlIjoiRGlzY292ZXIgbW9yZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlvhuqzGjCDHmseax5rFocSDxIPEg8Sj4bq94bq94bq9XSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiJb4bmuxIPEg8SDxprhur3hur3hur3guIHguLXguYnhua8gxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMeax5rHmsWhxIPEg8SDxKPhur3hur3hur1dIiwiRGlzY292ZXIgTW9yZSI6IlvhuIrDrMOsw6zFocOnw7bDtsO24bm94bq94bq94bq9xZkgbcyCw7bDtsO2xZnhur3hur3hur1dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBVc2FnZSI6IlvhuqzGjCDHmseax5rFocSDxIPEg8Sj4bq94bq94bq9XSIsIlRhbGVudCBTZWFyY2ggVXNhZ2UiOiJb4bmuxIPEg8SDxprhur3hur3hur3guIHguLXguYnhua8gxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMeax5rHmsWhxIPEg8SDxKPhur3hur3hur1dIiwiRGlzY292ZXIgTW9yZSI6IlvhuIrDrMOsw6zFocOnw7bDtsO24bm94bq94bq94bq9xZkgbcyCw7bDtsO2xZnhur3hur3hur1dIn0=!"
        )
      )
      });
  
      export { translations as default };
    