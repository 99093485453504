import {
  type ApolloCache,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';

import introspection from './introspection.json';
import { simpleArrayMerge } from './policies/simpleArrayMerge';

const possibleTypes: Record<string, string[]> = {};

introspection.__schema.types.forEach((supertype) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype) => subtype.name,
    );
  }
});

export const createCache = ({
  addTypename = false,
}: {
  addTypename?: boolean;
}): ApolloCache<NormalizedCacheObject> =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      AdSpendClassificationBreakdownConnection: {
        fields: {
          edges: simpleArrayMerge(),
        },
      },
      AdSpendHirerBreakdownConnection: {
        fields: {
          edges: simpleArrayMerge(),
        },
      },
    },
    addTypename,
  });
