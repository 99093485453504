
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiLguILguYnguK3guKHguLnguKXguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguInguLHguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiLguILguYnguK3guKHguLnguKXguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguInguLHguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJJbmZvcm1hc2kgbG93b25nYW4ga2VyamEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJJbmZvcm1hc2kgbG93b25nYW4ga2VyamEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiLguILguYnguK3guKHguLnguKXguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguInguLHguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiLguILguYnguK3guKHguLnguKXguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguInguLHguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJJbmZvcm1hc2kgbG93b25nYW4ga2VyamEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJJbmZvcm1hc2kgbG93b25nYW4ga2VyamEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJNeSBqb2IgaW5mb3JtYXRpb24ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJb4bmCw73DvcO9IMS1w7bDtsO2w58gw6zDrMOs4LiB4Li14LmJxpLDtsO2w7bFmW3MgsSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNeSBqb2IgaW5mb3JtYXRpb24iOiJb4bmCw73DvcO9IMS1w7bDtsO2w58gw6zDrMOs4LiB4Li14LmJxpLDtsO2w7bFmW3MgsSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    