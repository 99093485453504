import type { GraphQLError } from 'graphql';

type ErrorCode = string;

class AppError extends Error {
  code: ErrorCode;
  constructor(code: ErrorCode, message: string) {
    super(message);
    this.name = 'AppError';
    this.code = code;
  }
}

class GraphClientError<T extends Error> extends Error {
  readonly originalError?: T;

  constructor(error: T) {
    super(error.message);

    this.name = 'GraphClientError';
    this.originalError = error;
  }
}

class GraphClientRequestError extends GraphClientError<Error> {
  constructor(error: Error) {
    super(error);
    this.name = 'RequestError';
  }
}

class GraphClientGraphQLError extends GraphClientError<GraphQLError> {
  constructor(error: GraphQLError) {
    super(error);
    this.name = 'GraphQLError';
  }
}

export {
  AppError,
  GraphClientError,
  GraphClientRequestError,
  GraphClientGraphQLError,
};
