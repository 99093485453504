import { useTranslations } from '@vocab/react';
import { Stack, Text, Columns, Column, Loader } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ElapsedTime } from '../../components/ElapsedTime/ElapsedTime';
import { JobTitleNav } from '../../components/JobTitleNav/JobTitleNav';
import { PageTitleActions } from '../../components/PageTitleActions/PageTitleActions';
import { useAprMostRecentLastUpdatedTimestamp } from '../../hooks/useAprMostRecentLastUpdatedTimestamp';
import { usePageTitleActions } from '../../hooks/usePageTitleActions';
import { chartUtils } from '../../seek-charts';
import { formatDate } from '../../utils/date';

import translations from './.vocab';

export interface AdRatingToolbarProps {
  onQueryError?: () => void;
}

export const AdRatingToolbar = ({ onQueryError }: AdRatingToolbarProps) => {
  const { t } = useTranslations(translations);

  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const {
    data,
    isLoading: isLoadingPageTitleActions,
    error,
  } = usePageTitleActions({
    jobId: jobIdParam!,
  });
  const { mostRecentTimestamp, isLoading: isLoadingLastUpdatedTimestamp } =
    useAprMostRecentLastUpdatedTimestamp({
      jobId: jobIdParam!,
    });
  const hasQueryError = error || !data;

  const isLoading = isLoadingPageTitleActions || isLoadingLastUpdatedTimestamp;
  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  if (isLoading && !data) {
    return (
      <Stack align="center" space="small">
        <Loader />
      </Stack>
    );
  }

  if (hasQueryError) {
    return null;
  }

  const today = new Date();

  const daysLive = chartUtils.dateFormatter.daysAgoFromString(
    data.jobCreatedTimestampUTC,
  );
  const daysRemaining = Math.max(0, 30 - daysLive);
  const createdText = t('Created {createdDate}', {
    createdDate: formatDate({
      date: new Date(data.jobCreatedTimestampUTC),
      dateFormat: 'd MMM yyyy',
    }),
  });
  const expiredText =
    data.jobExpiryTimestampUTC && today > new Date(data.jobExpiryTimestampUTC)
      ? t('Expired {expiredDate}', {
          expiredDate: formatDate({
            date: new Date(data.jobExpiryTimestampUTC),
            dateFormat: 'dd MMM yyyy',
          }),
        })
      : t('Expires in X day(x)', {
          daysRemaining,
        });
  const prettyExpiredText = `${expiredText} - ${createdText}`;

  return (
    <Stack space="large">
      <Columns space="medium" alignY="center" collapseBelow="tablet">
        <Column>
          <JobTitleNav
            activeJobId={data?.jobId}
            jobTitle={data?.jobTitle}
            postedViaUploader={data?.postTypeCategory !== 'DIRECT'}
            latestJobs={data?.latestJobs}
            isLoading={isLoading}
          />
        </Column>
        <Column width="content">
          <PageTitleActions data={data} isLoading={isLoading} error={error} />
        </Column>
      </Columns>
      <Stack space="medium">
        <Text tone="secondary">{prettyExpiredText}</Text>
        <Text tone="secondary">
          {mostRecentTimestamp ? (
            <ElapsedTime pastTime={mostRecentTimestamp}>
              {({ renderProps }) =>
                t(`Below stats last updated: ${renderProps.type}`, {
                  value: renderProps.value,
                })
              }
            </ElapsedTime>
          ) : null}
        </Text>
      </Stack>
    </Stack>
  );
};
