import { useTranslations } from '@vocab/react';
import { Box, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { formatNumber } from '../../utils/number';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialogNew';
import { Medium } from '../Medium/Medium';
import { Metric } from '../Metric/Metric';
import { Trend } from '../Trend/Trend';

import translations from './.vocab';
import { mapComparisonRateToTrendParts } from './AdStatCard.formatters';

import * as styles from './adStatCard.css';

export interface AdStatCardProps {
  dataId?: string;
  adRatingConfidence: string | null;
  statCount: number;
  conversionLabel?: ReactNode;
  headingLabel: string;
  headingTooltip: ReactNodeNoStrings;
  mobileInPercentage: number;
  similarAdsInPercentage: number | null;
  similarAdsAverage: number | null;
  displayMarketPerformance: boolean;
  hasBorder?: boolean;
  isStandalone?: boolean;
}

export const AdStatCard = ({
  dataId,
  adRatingConfidence,
  statCount,
  conversionLabel,
  headingLabel,
  headingTooltip,
  mobileInPercentage,
  similarAdsInPercentage,
  similarAdsAverage,
  displayMarketPerformance,
  hasBorder = true,
  isStandalone,
}: AdStatCardProps) => {
  const { t } = useTranslations(translations);

  const similarAdsTooltip = () => {
    const tip =
      adRatingConfidence === 'LOW'
        ? 'Not enough roles in location'
        : 'Performance compared to recent ads';
    return <Text size="small">{t(tip)}</Text>;
  };

  const similarAdsDialog = (label: ReactNode) => (
    <DisclosureDialog id="similar-ads-tooltip" tooltip={similarAdsTooltip()}>
      {({ triggerProps }) => (
        <span role="button" {...triggerProps}>
          {label}
        </span>
      )}
    </DisclosureDialog>
  );

  const renderSimilarAdsComparison = () => {
    if (similarAdsInPercentage === null) {
      return null;
    }

    if (similarAdsInPercentage === 0) {
      return (
        <Text size="small" tone="secondary" weight="regular">
          {t('Equal to similar ads', {
            Tooltip: (label) => similarAdsDialog(label),
          })}
        </Text>
      );
    }

    const similarAdsTrend = mapComparisonRateToTrendParts(
      similarAdsInPercentage,
    );

    return (
      <Text size="small" tone="secondary">
        {t(`${similarAdsTrend.metricSuffix} than similar ads`, {
          metric: similarAdsTrend.metricLabel,
          Trend: (label) => (
            <Trend
              isPositiveTrend={similarAdsTrend.isPositiveTrend}
              isInlineTrend={similarAdsTrend.isInlineTrend}
              showTrendArrow={false}
              size="small"
            >
              {label}
            </Trend>
          ),
          Tooltip: (label) => similarAdsDialog(label),
        })}
      </Text>
    );
  };

  return (
    <Box
      data-id={dataId}
      borderRadius="standard"
      height="full"
      className={hasBorder ? styles.adStatCard : undefined}
    >
      <Box background="body" borderRadius="standard" height="full" width="full">
        <Box display="flex" flexDirection="column" height="full">
          <Box
            background="surface"
            borderRadius="standard"
            paddingY="gutter"
            paddingX="medium"
            flexGrow={isStandalone ? 0 : 1}
          >
            <Stack space="large">
              <Box display="inlineBlock">
                <Text weight="strong">
                  <DisclosureDialog
                    id="heading-tooltip"
                    tooltip={headingTooltip}
                  >
                    {({ triggerProps }) => (
                      <span role="button" {...triggerProps}>
                        {headingLabel}
                      </span>
                    )}
                  </DisclosureDialog>
                </Text>
              </Box>
              <Stack space="medium">
                <Metric
                  metric={statCount}
                  metricNotation="compact"
                  metricSize="large"
                />
                <Stack space="small">
                  <Text tone="secondary">
                    {t('{percentage} from a mobile device', {
                      percentage: formatPercentage(mobileInPercentage, 0),
                      Medium: (text) => <Medium>{text}</Medium>,
                    })}
                  </Text>
                  {Boolean(conversionLabel) && (
                    <Text tone="secondary">{conversionLabel}</Text>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
          {displayMarketPerformance && similarAdsAverage !== null && (
            <Box paddingY="gutter" paddingX="medium">
              <Stack space="medium">
                <Text size="small" tone="secondary" weight="strong">
                  {t('Market performance')}
                </Text>
                <Stack space="small">
                  {renderSimilarAdsComparison()}
                  <Text size="small" tone="secondary">
                    {t(`Similar ads average {average}`, {
                      average: formatNumber(similarAdsAverage, {
                        notation: 'compact',
                      }),
                      Medium: (value) => <Medium>{value}</Medium>,
                    })}
                  </Text>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
