import { useTranslations } from '@vocab/react';
import { Column, Columns, IconPeople, Stack, Text } from 'braid-design-system';
import type React from 'react';

import type { PostTypeCategory } from '../../types/AdPerformanceTypes';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { RecessedPill } from '../RecessedPill/RecessedPill';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';
import { RoleRequirementsSkeleton } from '../RoleRequirementsSkeleton/RoleRequirementsSkeleton';
import { WidgetError } from '../WidgetError/WidgetError';

import translations from './.vocab';

export interface RoleRequirementsRecommendationProps {
  roleReqsQuestions: string[];
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  editAdUrl: string;
  coverTitle?: string;
  coverSubTitle?: ReactNodeNoStrings;
  direction?: 'row' | 'column';
  footerNotice?: ReactNodeNoStrings;
  showQuestions?: boolean;
  showError?: boolean;
  trackInteractionObject?: string;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  onTipClicked?: () => void;
}

export const RoleRequirementsRecommendation = ({
  roleReqsQuestions,
  isExpired,
  postTypeCategory,
  editAdUrl,
  coverTitle,
  coverSubTitle,
  direction = 'row',
  footerNotice = null,
  showQuestions = true,
  showError = false,
  trackInteractionObject,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  onTipClicked,
}: RoleRequirementsRecommendationProps) => {
  const { t } = useTranslations(translations);

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      if (onTipClicked) {
        onTipClicked();
      } else {
        trackEvent('view_tip_pressed', {
          tipName: 'job details',
          objectInteraction:
            trackInteractionObject || 'apr-recommendations-role-reqs-section',
        });
      }
    }
  };

  const title = t('Make shortlisting easier for your next job ad');
  const subTitle = t(
    "Use Questions for Candidates to help you sort candidates who meet your requirements from those who don't.",
  );

  return (
    <RecommendationSkeleton
      coverTitle={coverTitle}
      coverSubTitle={coverSubTitle}
      direction={direction}
      titleContent={title}
      subtitleContent={subTitle}
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconPeople tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-role-requirements-recommendation"
    >
      <Stack space="medium">
        <Columns collapseBelow="tablet" space="xlarge">
          <Column>
            <Stack space="large">
              <Text>
                {t('Filter candidates based on answers to questions')}
              </Text>
              <Text>{t('Why questions cannot be changed')}</Text>
              {showQuestions && (
                <Stack space="medium">
                  <Text>
                    {roleReqsQuestions.length || showError
                      ? t('Example role requirements questions heading', {
                          exampleCount: roleReqsQuestions.length,
                        })
                      : null}
                  </Text>
                  {showError ? (
                    <WidgetError />
                  ) : (
                    <Stack space="small">
                      {roleReqsQuestions.map((r, i) => (
                        <RecessedPill
                          key={`ui-role-requirements-question-${i}`}
                        >
                          <Text tone="secondary">{r}</Text>
                        </RecessedPill>
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Column>
          <Column width="content">
            <RoleRequirementsSkeleton />
          </Column>
        </Columns>
        {footerNotice}
      </Stack>
    </RecommendationSkeleton>
  );
};
