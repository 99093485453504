import { gql } from '@apollo/client';

export const applicationPerformanceQuery = gql`
  query GetApplicationPerformance($input: ApplicationPerformanceWidgetInput!) {
    analyticsApplicationPerformance(input: $input) {
      isLinkOutAd
      adRatingConfidence
      applications
      lastUpdatedTimestampUTC
      mobileViewsInPercentage
      similarAdsInPercentage
      similarAdsAverage
      hasAdRating
    }
  }
`;
