
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgU1RBTkRPVVQiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgU3RhbmRPdXQiLCJCYWRnZSBQUkVNSVVNIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIFByZW1pdW0iLCJCYWRnZSBCUkFOREVEIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIEJyYW5kZWQiLCJCYWRnZSBDTEFTU0lDIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIENsYXNzaWMiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQXV0byBJbmNsdWRlIiwiQmFkZ2UgQkFTSUMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQmFzaWMiLCJQcml2YXRlIEFkdmVydGlzZXIiOiLguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKPguYTguKHguYjguYDguJvguLTguJTguYDguJzguKLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguIrguLHguYjguKfguYLguKHguId9IG9uZSB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvdGhlciB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfX3guJfguLXguYjguYHguKXguYnguKciLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge+C4p+C4seC4mX0gb25lIHvguKfguLHguJl9IG90aGVyIHvguKfguLHguJl9feC4l+C4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgU1RBTkRPVVQiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgU3RhbmRPdXQiLCJCYWRnZSBQUkVNSVVNIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIFByZW1pdW0iLCJCYWRnZSBCUkFOREVEIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIEJyYW5kZWQiLCJCYWRnZSBDTEFTU0lDIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIENsYXNzaWMiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQXV0byBJbmNsdWRlIiwiQmFkZ2UgQkFTSUMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQmFzaWMiLCJQcml2YXRlIEFkdmVydGlzZXIiOiLguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKPguYTguKHguYjguYDguJvguLTguJTguYDguJzguKLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguIrguLHguYjguKfguYLguKHguId9IG9uZSB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvdGhlciB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfX3guJfguLXguYjguYHguKXguYnguKciLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge+C4p+C4seC4mX0gb25lIHvguKfguLHguJl9IG90aGVyIHvguKfguLHguJl9feC4l+C4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBTVEFORE9VVCI6IlN0YW5kT3V0IEFkIiwiQmFkZ2UgUFJFTUlVTSI6IlByZW1pdW0gQWQiLCJCYWRnZSBCUkFOREVEIjoiQnJhbmRlZCBBZCIsIkJhZGdlIENMQVNTSUMiOiJDbGFzc2ljIEFkIiwiQmFkZ2UgQVVUT19JTkNMVURFIjoiQXV0byBJbmNsdWRlIEFkIiwiQmFkZ2UgQkFTSUMiOiJCYXNpYyBBZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlBlbmdpa2xhbiBQcml2YXQiLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiRGlwb3N0aW5nIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBTVEFORE9VVCI6IlN0YW5kT3V0IEFkIiwiQmFkZ2UgUFJFTUlVTSI6IlByZW1pdW0gQWQiLCJCYWRnZSBCUkFOREVEIjoiQnJhbmRlZCBBZCIsIkJhZGdlIENMQVNTSUMiOiJDbGFzc2ljIEFkIiwiQmFkZ2UgQVVUT19JTkNMVURFIjoiQXV0byBJbmNsdWRlIEFkIiwiQmFkZ2UgQkFTSUMiOiJCYXNpYyBBZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlBlbmdpa2xhbiBQcml2YXQiLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiRGlwb3N0aW5nIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgU1RBTkRPVVQiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgU3RhbmRPdXQiLCJCYWRnZSBQUkVNSVVNIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIFByZW1pdW0iLCJCYWRnZSBCUkFOREVEIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIEJyYW5kZWQiLCJCYWRnZSBDTEFTU0lDIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIENsYXNzaWMiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQXV0byBJbmNsdWRlIiwiQmFkZ2UgQkFTSUMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQmFzaWMiLCJQcml2YXRlIEFkdmVydGlzZXIiOiLguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKPguYTguKHguYjguYDguJvguLTguJTguYDguJzguKLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguIrguLHguYjguKfguYLguKHguId9IG9uZSB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvdGhlciB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfX3guJfguLXguYjguYHguKXguYnguKciLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge+C4p+C4seC4mX0gb25lIHvguKfguLHguJl9IG90aGVyIHvguKfguLHguJl9feC4l+C4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgU1RBTkRPVVQiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgU3RhbmRPdXQiLCJCYWRnZSBQUkVNSVVNIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIFByZW1pdW0iLCJCYWRnZSBCUkFOREVEIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIEJyYW5kZWQiLCJCYWRnZSBDTEFTU0lDIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIENsYXNzaWMiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQXV0byBJbmNsdWRlIiwiQmFkZ2UgQkFTSUMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJkgQmFzaWMiLCJQcml2YXRlIEFkdmVydGlzZXIiOiLguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKPguYTguKHguYjguYDguJvguLTguJTguYDguJzguKLguIrguLfguYjguK3guJrguKPguLTguKnguLHguJciLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguIrguLHguYjguKfguYLguKHguId9IG9uZSB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvdGhlciB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfX3guJfguLXguYjguYHguKXguYnguKciLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge+C4p+C4seC4mX0gb25lIHvguKfguLHguJl9IG90aGVyIHvguKfguLHguJl9feC4l+C4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IuC4peC4h+C4m+C4o+C4sOC4geC4suC4qCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBTVEFORE9VVCI6IlN0YW5kT3V0IEFkIiwiQmFkZ2UgUFJFTUlVTSI6IlByZW1pdW0gQWQiLCJCYWRnZSBCUkFOREVEIjoiQnJhbmRlZCBBZCIsIkJhZGdlIENMQVNTSUMiOiJDbGFzc2ljIEFkIiwiQmFkZ2UgQVVUT19JTkNMVURFIjoiQXV0byBJbmNsdWRlIEFkIiwiQmFkZ2UgQkFTSUMiOiJCYXNpYyBBZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlBlbmdpa2xhbiBQcml2YXQiLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiRGlwb3N0aW5nIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBTVEFORE9VVCI6IlN0YW5kT3V0IEFkIiwiQmFkZ2UgUFJFTUlVTSI6IlByZW1pdW0gQWQiLCJCYWRnZSBCUkFOREVEIjoiQnJhbmRlZCBBZCIsIkJhZGdlIENMQVNTSUMiOiJDbGFzc2ljIEFkIiwiQmFkZ2UgQVVUT19JTkNMVURFIjoiQXV0byBJbmNsdWRlIEFkIiwiQmFkZ2UgQkFTSUMiOiJCYXNpYyBBZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlBlbmdpa2xhbiBQcml2YXQiLCJQb3N0ZWQgWCBob3VyKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiRGlwb3N0aW5nIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIFNUQU5ET1VUIjoiU3RhbmRPdXQgYWQiLCJCYWRnZSBQUkVNSVVNIjoiUHJlbWl1bSBhZCIsIkJhZGdlIEJSQU5ERUQiOiJCcmFuZGVkIGFkIiwiQmFkZ2UgQ0xBU1NJQyI6IkNsYXNzaWMgYWQiLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJBdXRvIEluY2x1ZGUgYWQiLCJCYWRnZSBCQVNJQyI6IkJhc2ljIGFkIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiUHJpdmF0ZSBBZHZlcnRpc2VyIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJQb3N0ZWQge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge21vbnRofSBvbmUge21vbnRofSBvdGhlciB7bW9udGhzfX0gYWdvIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJb4biw4bq94bq94bq9w73DvcO9IMWh4bq94bq94bq9xprGmsOsw6zDrOC4geC4teC5icSjIMalw7bDtsO2w6zDrMOs4LiB4Li14LmJ4bmvxaFdIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IlvFoMSDxIPGmsSDxIPFmcO9w70gxozDrMOsxaHGpcaaxIPEg8O9w73hur3hur3GjF0iLCJCYWRnZSBTVEFORE9VVCI6IlvFoOG5r8SDxIPEg+C4geC4teC5icaMw5bHmseax5rhua8gxIPEg8SDxoxdIiwiQmFkZ2UgUFJFTUlVTSI6IlvGpMWZ4bq94bq9bcyCw6zDrMeax5ptzIIgxIPEg8aMXSIsIkJhZGdlIEJSQU5ERUQiOiJb4bqexZnEg8SDxIPguIHguLXguYnGjOG6veG6veG6vcaMIMSDxIPEg8aMXSIsIkJhZGdlIENMQVNTSUMiOiJbw4fGmsSDxIPEg8WhxaHDrMOsw6zDpyDEg8SDxIPGjF0iLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJb4bqsx5rHmsea4bmvw7bDtsO2IMOP4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IMSDxIPEg8aMXSIsIkJhZGdlIEJBU0lDIjoiW+G6nsSDxIPEg8Whw6zDrMOsw6cgxIPEg8SDxoxdIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiW8akxZnDrMOsw6zhub3Eg8SDxIPhua/hur3hur3hur0g4bqsxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZldIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r+G6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we+G4qcO2w7bHmseaxZl9IG9uZXvhuKnDtsO2x5rHmsWZfSBvdGhlcnvhuKnDtsO2x5rHmsWZxaF9fSDEg8SDxKPDtsO2XSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r+G6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we8aMxIPEg8O9w719IG9uZXvGjMSDxIPDvcO9fSBvdGhlcnvGjMSDxIPDvcO9xaF9fSDEg8SDxKPDtsO2XSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlvGpMO2w7bDtsWh4bmv4bq94bq94bq9xowge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSxwbHVyYWwsPTB7bcyCw7bDtsO24LiB4Li14LmJ4bmv4bipfSBvbmV7bcyCw7bDtsO24LiB4Li14LmJ4bmv4bipfSBvdGhlcnttzILDtsO2w7bDtuC4geC4teC5ieG5r+G4qcWhfX0gxIPEg8Sjw7bDtl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJb4biw4bq94bq94bq9w73DvcO9IMWh4bq94bq94bq9xprGmsOsw6zDrOC4geC4teC5icSjIMalw7bDtsO2w6zDrMOs4LiB4Li14LmJ4bmvxaFdIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IlvFoMSDxIPGmsSDxIPFmcO9w70gxozDrMOsxaHGpcaaxIPEg8O9w73hur3hur3GjF0iLCJCYWRnZSBTVEFORE9VVCI6IlvFoOG5r8SDxIPEg+C4geC4teC5icaMw5bHmseax5rhua8gxIPEg8SDxoxdIiwiQmFkZ2UgUFJFTUlVTSI6IlvGpMWZ4bq94bq9bcyCw6zDrMeax5ptzIIgxIPEg8aMXSIsIkJhZGdlIEJSQU5ERUQiOiJb4bqexZnEg8SDxIPguIHguLXguYnGjOG6veG6veG6vcaMIMSDxIPEg8aMXSIsIkJhZGdlIENMQVNTSUMiOiJbw4fGmsSDxIPEg8WhxaHDrMOsw6zDpyDEg8SDxIPGjF0iLCJCYWRnZSBBVVRPX0lOQ0xVREUiOiJb4bqsx5rHmsea4bmvw7bDtsO2IMOP4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IMSDxIPEg8aMXSIsIkJhZGdlIEJBU0lDIjoiW+G6nsSDxIPEg8Whw6zDrMOsw6cgxIPEg8SDxoxdIiwiUHJpdmF0ZSBBZHZlcnRpc2VyIjoiW8akxZnDrMOsw6zhub3Eg8SDxIPhua/hur3hur3hur0g4bqsxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZldIiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r+G6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we+G4qcO2w7bHmseaxZl9IG9uZXvhuKnDtsO2x5rHmsWZfSBvdGhlcnvhuKnDtsO2x5rHmsWZxaF9fSDEg8SDxKPDtsO2XSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r+G6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we8aMxIPEg8O9w719IG9uZXvGjMSDxIPDvcO9fSBvdGhlcnvGjMSDxIPDvcO9xaF9fSDEg8SDxKPDtsO2XSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlvGpMO2w7bDtsWh4bmv4bq94bq94bq9xowge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSxwbHVyYWwsPTB7bcyCw7bDtsO24LiB4Li14LmJ4bmv4bipfSBvbmV7bcyCw7bDtsO24LiB4Li14LmJ4bmv4bipfSBvdGhlcnttzILDtsO2w7bDtuC4geC4teC5ieG5r+G4qcWhfX0gxIPEg8Sjw7bDtl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    