import type { FieldPolicy, Reference } from '@apollo/client/cache';

export function simpleArrayMerge<TNode = Reference>(): FieldPolicy<TNode[]> {
  return {
    keyArgs: false,
    merge: (existing, incoming) => {
      if (!existing) {
        return incoming;
      }
      return [...existing, ...incoming];
    },
  };
}
