import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { pageTitleActionsQuery } from '../queries/pageTitleActionsQuery';
import type { PageTitleActionsResponse } from '../types/PageTitleActionsResponse';

interface PageTitleActionsQueryParams {
  input: {
    jobId: string;
    locale: string;
  };
}

export const usePageTitleActions = ({
  jobId,
}: {
  jobId: string;
}): {
  data: PageTitleActionsResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery<
    { analyticsPageTitleActions: PageTitleActionsResponse },
    PageTitleActionsQueryParams
  >(pageTitleActionsQuery, {
    skip: !jobId,
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsPageTitleActions,
    isLoading: loading,
    error,
  };
};
