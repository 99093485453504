import { useEffect, useState } from 'react';

import { mockHeaderFooterContext } from '../__fixtures__/mockHeaderFooterContext';
import { isLocal } from '../config';
import type { HeaderFooterAccountInfo } from '../types/HeaderFooterAccount';

import { useHeaderFooterContext } from './useHeaderFooterContext';

export const useAdCentreAccountInfo = (
  authToken: string | null,
): {
  data: HeaderFooterAccountInfo | null;
  error: Error | undefined;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<HeaderFooterAccountInfo | null>(null);
  const selectedAdvertiserIdString = window.localStorage.getItem(
    'adv-account-selection-account-id',
  );

  const {
    data: headerFooterData,
    error: headerFooterError,
    isLoading: isLoadingHeaderFooter,
  } = useHeaderFooterContext(Boolean(authToken));

  useEffect(() => {
    if (authToken && !isLoadingHeaderFooter) {
      if (isLocal()) {
        // return a mock since we can't retrieve a talent.seek.com.au.staging local storage item from a localhost domain
        setData(mockHeaderFooterContext);
        setIsLoading(false);
        return;
      }

      try {
        // guard against missing advertiser selection
        if (!selectedAdvertiserIdString) {
          throw new Error('No selected advertiser ID');
        }
        const selectedAdvertiserId = parseInt(selectedAdvertiserIdString, 10);
        if (!selectedAdvertiserId || selectedAdvertiserId === 0) {
          throw new Error('Invalid selected advertiser ID');
        }
      } catch (err) {
        // dont console log this error
        setError(err as Error);
        setIsLoading(false);
        return;
      }

      setData(headerFooterData);
      setError(headerFooterError);
      setIsLoading(isLoadingHeaderFooter);
    }
  }, [
    authToken,
    selectedAdvertiserIdString,
    headerFooterData,
    headerFooterError,
    isLoadingHeaderFooter,
  ]);

  return {
    data,
    error,
    isLoading,
  };
};
