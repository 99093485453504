export const NoAccessIcon = () => (
  <svg
    width="101"
    height="87"
    viewBox="0 0 101 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1535_51872)">
      <path
        d="M91.2151 71.1993C91.2151 76.7062 86.7151 81.2118 81.2151 81.2118H10.5723C5.07227 81.2118 0.572266 76.7062 0.572266 71.1993V15.0579C0.572266 9.55103 5.07227 5.04541 10.5723 5.04541H81.2151C86.7151 5.04541 91.2151 9.55103 91.2151 15.0579V71.1993Z"
        fill="#EAF0FA"
      />
      <path
        d="M46.3574 30.9619C46.286 30.9619 46.1431 31.0335 46.0717 31.0335L28.9288 38.3998C28.5717 38.5428 28.3574 38.9004 28.3574 39.3295C28.3574 50.4147 30.6431 57.5666 34.0717 62.5728C37.5003 67.579 41.9288 70.3682 45.9288 73.0144C46.286 73.2289 46.6431 73.2289 47.0003 73.0144C51.0717 70.4397 55.5003 67.579 58.8574 62.5728C62.286 57.5666 64.5717 50.4147 64.5717 39.3295C64.5717 38.9004 64.3574 38.6143 64.0003 38.3998L46.8574 31.0335C46.7146 31.0335 46.5003 30.9619 46.3574 30.9619Z"
        fill="#FAFAFA"
      />
      <path
        d="M51.8577 44.0224C50.7148 42.163 48.5719 41.0187 46.2148 41.2333C43.6434 41.5193 41.5005 43.5933 41.1434 46.168C40.7862 48.7426 42.0719 51.1027 44.1434 52.1755L42.4291 60.7576C42.2862 61.6158 42.9291 62.4025 43.7862 62.4025H50.0005C50.8577 62.4025 51.5719 61.6158 51.3576 60.7576L49.6434 52.1755C50.5719 51.6748 51.3577 50.9597 51.8577 50.1015"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M80.3583 53.1768C82.2868 55.966 85.5011 57.8255 89.144 57.8255C95.0011 57.8255 99.7154 53.1053 99.7154 47.2409C99.7154 41.3764 95.0011 36.6562 89.144 36.6562C83.2868 36.6562 78.5725 41.3764 78.5725 47.2409H46.9297"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1.64258 22.2095H91.214"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.07059 15.773C10.2541 15.773 11.2134 14.8124 11.2134 13.6275C11.2134 12.4425 10.2541 11.4819 9.07059 11.4819C7.88712 11.4819 6.92773 12.4425 6.92773 13.6275C6.92773 14.8124 7.88712 15.773 9.07059 15.773Z"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.9983 15.773C19.1818 15.773 20.1412 14.8124 20.1412 13.6275C20.1412 12.4425 19.1818 11.4819 17.9983 11.4819C16.8149 11.4819 15.8555 12.4425 15.8555 13.6275C15.8555 14.8124 16.8149 15.773 17.9983 15.773Z"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.928 15.773C28.1115 15.773 29.0709 14.8124 29.0709 13.6275C29.0709 12.4425 28.1115 11.4819 26.928 11.4819C25.7445 11.4819 24.7852 12.4425 24.7852 13.6275C24.7852 14.8124 25.7445 15.773 26.928 15.773Z"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1535_51872">
        <rect
          width="100"
          height="86.1789"
          fill="white"
          transform="translate(0.5 0.0390625)"
        />
      </clipPath>
    </defs>
  </svg>
);
