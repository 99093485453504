import type React from 'react';

import { RoundedCard, type RoundedCardProps } from '../RoundedCard/RoundedCard';

export const PrimarySection = ({
  children,
  ...otherProps
}: RoundedCardProps) => (
  <RoundedCard
    data-id="primitive-primary-section"
    padding="large"
    {...otherProps}
  >
    {children}
  </RoundedCard>
);
