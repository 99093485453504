import { useTranslations } from '@vocab/react';
import { Box, Text, Stack, Heading, Loader, Strong } from 'braid-design-system';
import { useState, useRef, useEffect } from 'react';

import { SearchHeroIcon } from '../../App/icons/SearchHeroIcon';
import { Carousel } from '../../components/Carousel/Carousel';
import { DisclosureDialog } from '../../components/DisclosureDialog/DisclosureDialogNew';
import {
  PerformanceRatingCard,
  type PerformanceRatingCardProps,
} from '../../components/PerformanceRatingCard/PerformanceRatingCard';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { getLocale } from '../../config';
import { useMostAdsByPerformanceData } from '../../hooks/useMostAdsByPerformanceData';

import translations from './.vocab';

import * as styles from './MostAdsByPerformance.css';

const OuterContainer = ({ children }: { children: ReactNodeNoStrings }) => (
  <Box display="flex" flexDirection="column">
    {children}
  </Box>
);

export const MostAdsByPerformanceTitle = ({ isLoading = false }) => {
  const { t } = useTranslations(translations);

  const translationRendererOptions: any = {
    Disclosure: (children: string) => (
      <DisclosureDialog
        id="most-ads-by-performance-header-tooltip"
        tooltip={t('performance rating tooltip', {
          Stack: (child: ReactNodeNoStrings) => (
            <Stack space="medium">{child}</Stack>
          ),
          Text: (child: ReactNodeNoStrings) => (
            <Text key={child} size="small">
              {child}
            </Text>
          ),
        })}
        key={children}
      >
        {({ triggerProps }) => <span {...triggerProps}>{children}</span>}
      </DisclosureDialog>
    ),
  };

  return (
    <Box marginTop="small" marginBottom={isLoading ? 'medium' : 'small'}>
      <Text>{t('title', translationRendererOptions)}</Text>
    </Box>
  );
};

export const MostAdsByPerformance = () => {
  const outerWrapperRef = useRef<HTMLElement>(null);
  const [isCarouselMode, setIsCarouselMode] = useState<boolean>(true);

  const { t } = useTranslations(translations);

  const { data, isLoading, error } = useMostAdsByPerformanceData();

  useEffect(() => {
    if (data && !isLoading) {
      if (
        outerWrapperRef?.current?.offsetWidth &&
        outerWrapperRef.current?.offsetWidth < 551
      ) {
        setIsCarouselMode(false);
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (!outerWrapperRef.current?.offsetWidth) {
        return;
      }

      if (outerWrapperRef.current.offsetWidth < 551 && isCarouselMode) {
        setIsCarouselMode(false);
      }

      if (outerWrapperRef.current.offsetWidth >= 551 && !isCarouselMode) {
        setIsCarouselMode(true);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isCarouselMode]);

  if (isLoading && !data) {
    return (
      <Stack space="large">
        <MostAdsByPerformanceTitle isLoading={isLoading} />
        <Box display="flex" justifyContent="center" margin="medium">
          <Loader size="small" />
        </Box>
      </Stack>
    );
  }

  if (error || !data) {
    return (
      <OuterContainer>
        <MostAdsByPerformanceTitle />
        <Box paddingTop="medium">
          <WidgetError />
        </Box>
      </OuterContainer>
    );
  }

  const sectionClassName = isCarouselMode
    ? styles.slideContainer
    : styles.cardsContainer;

  const {
    low: {
      user: {
        label: userLowLabel,
        number: userLowNumber,
        percentage: userLowPercentage,
      },
      classification: {
        label: classificationLowLabel,
        number: classificationLowNumber,
        percentage: classificationLowPercentage,
      },
    },
    high: {
      user: {
        label: userHighLabel,
        number: userHighNumber,
        percentage: userHighPercentage,
      },
      classification: {
        label: classificationHighLabel,
        number: classificationHighNumber,
        percentage: classificationHighPercentage,
      },
    },
  } = data;
  const numberFormatter = new Intl.NumberFormat(getLocale());
  const percentageFormatter = new Intl.NumberFormat(getLocale(), {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const noResultsTranslationRendererOptions: any = {
    Stack: (child: ReactNodeNoStrings) => (
      <Stack space="small" align="center">
        {child}
      </Stack>
    ),
    Text: (children: string) => (
      <Text key={children} tone="secondary">
        {children}
      </Text>
    ),
  };

  const NoPerformanceRatingDataPlaceholder = () => (
    <Box
      style={{ maxWidth: 580, width: '100%' }}
      marginBottom="small"
      display="flex"
      justifyContent="center"
    >
      <Stack space="large">
        <Box textAlign="center">
          <SearchHeroIcon />
        </Box>
        <Heading level="3" align="center">
          {t('no results title')}
        </Heading>
        <>{t('no results description', noResultsTranslationRendererOptions)}</>
      </Stack>
    </Box>
  );

  const noHighOrLowRatingAds =
    userHighNumber +
      userLowNumber +
      classificationHighNumber +
      classificationLowNumber ===
    0;

  if (noHighOrLowRatingAds) {
    return (
      <OuterContainer>
        <MostAdsByPerformanceTitle />
        <Box paddingX="large">
          <NoPerformanceRatingDataPlaceholder />
        </Box>
      </OuterContainer>
    );
  }

  const cards: PerformanceRatingCardProps[] = [];

  if (userLowNumber) {
    cards.push({
      rating: t('Low'),
      ratingTone: 'critical',
      description:
        userLowLabel === null
          ? t('unspecified user low performance rating description', {
              adCount: userLowNumber,
              adCountText: numberFormatter.format(userLowNumber),
              Strong: (text) => <Strong>{text}</Strong>,
            })
          : t('user low performance rating description', {
              adCount: userLowNumber,
              adCountText: numberFormatter.format(userLowNumber),
              user: userLowLabel,
              Strong: (text) => <Strong>{text}</Strong>,
            }),
      caption: t('user performance rating card caption', {
        percentage: percentageFormatter.format(userLowPercentage / 100),
      }),
    });
  }

  if (userHighNumber) {
    cards.push({
      rating: t('High'),
      ratingTone: 'positive',
      description:
        userHighLabel === null
          ? t('unspecified user high performance rating description', {
              adCount: userHighNumber,
              adCountText: numberFormatter.format(userHighNumber),
              Strong: (text) => <Strong>{text}</Strong>,
            })
          : t('user high performance rating description', {
              adCount: userHighNumber,
              adCountText: numberFormatter.format(userHighNumber),
              user: userHighLabel,
              Strong: (text) => <Strong>{text}</Strong>,
            }),
      caption: t('user performance rating card caption', {
        percentage: percentageFormatter.format(userHighPercentage / 100),
      }),
    });
  }

  if (classificationLowNumber) {
    cards.push({
      rating: t('Low'),
      ratingTone: 'critical',
      description: t('classification low performance rating description', {
        adCount: classificationLowNumber,
        adCountText: numberFormatter.format(classificationLowNumber),
        classification: classificationLowLabel!,
        Strong: (text) => <Strong>{text}</Strong>,
      }),
      caption: t('classification performance rating card caption', {
        percentage: percentageFormatter.format(
          classificationLowPercentage / 100,
        ),
      }),
    });
  }

  if (classificationHighNumber) {
    cards.push({
      rating: t('High'),
      ratingTone: 'positive',
      description: t('classification high performance rating description', {
        adCount: classificationHighNumber,
        adCountText: numberFormatter.format(classificationHighNumber),
        classification: classificationHighLabel!,
        Strong: (text) => <Strong>{text}</Strong>,
      }),
      caption: t('classification performance rating card caption', {
        percentage: percentageFormatter.format(
          classificationHighPercentage / 100,
        ),
      }),
    });
  }

  const carouselGroups = cards.reduce(
    (result: PerformanceRatingCardProps[][], current) => {
      const newResult = [...result];
      if (!newResult[0]) {
        newResult[0] = [current];
      } else if (newResult[0].length < 3) {
        newResult[0].push(current);
      } else {
        newResult[1] = [...(newResult[1] || []), current];
      }
      return newResult;
    },
    [],
  );

  const elements: ReactNodeNoStrings[] = carouselGroups
    .map((carouselSlideElements, index) =>
      carouselSlideElements.length ? (
        <Box key={index} display="flex" className={sectionClassName}>
          {carouselSlideElements.map((slideElement, slideElementIndex) => (
            <PerformanceRatingCard key={slideElementIndex} {...slideElement} />
          ))}
        </Box>
      ) : null,
    )
    .filter(Boolean);

  return (
    <OuterContainer>
      <MostAdsByPerformanceTitle />
      <Box ref={outerWrapperRef} display="flex">
        {isCarouselMode ? (
          <Box style={{ width: 608 }} display="flex">
            <Box style={{ width: 'calc(100% - 60px)' }}>
              <Carousel elements={elements} />
            </Box>
          </Box>
        ) : (
          <Box style={{ width: '100%' }}>
            <Box display="flex" style={{ overflowX: 'auto' }}>
              {elements.map((element) => element)}
            </Box>
          </Box>
        )}
      </Box>
    </OuterContainer>
  );
};
