import { useTranslations } from '@vocab/react';

import { GenericTitleContainer } from '../../components/GenericTitleContainer/GenericTitleContainer';
import { RoundedCard } from '../../components/RoundedCard/RoundedCard';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import translations from './.vocab';

export const RecommendationsFallback = () => {
  const { t } = useTranslations(translations);

  return (
    <RoundedCard>
      <GenericTitleContainer title={t('Tips for your ad')}>
        <WidgetError />
      </GenericTitleContainer>
    </RoundedCard>
  );
};
