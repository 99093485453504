import { AuthN, type ClientConfig } from '@seek/online-identity';
import type { IdentitySubject } from '@seek/online-identity/lib-types/types';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  type ReactNode,
} from 'react';

import { getDevToken, isLocal } from '../config';

import { AuthContext } from './AuthContext';

export interface AuthProviderProps {
  children: ReactNode;
  config: ClientConfig;
}

export const AuthProvider = ({ children, config }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userIdentity, setUserIdentity] = useState<IdentitySubject | null>(
    null,
  );
  const isLoadingRedirect = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authN, setAuthN] = useState<AuthN | null>(null);

  const isSeekStaff = useMemo(() => {
    const userEmail = userIdentity?.email || '';
    return userEmail.includes('@seek.co');
  }, [userIdentity]);

  useEffect(() => {
    const newAuthN = new AuthN(config);
    setAuthN(newAuthN);
  }, [config]);

  useEffect(() => {
    async function initAuth() {
      if (authN === null) {
        return;
      }
      try {
        await authN.init();

        // ensure session status is authenticated
        const newIsAuthenticated = await authN.isAuthenticated();
        setIsAuthenticated(newIsAuthenticated);
        if (!newIsAuthenticated) {
          throw new Error('User is not authenticated');
        }

        const newUserIdentity = await authN.getIdentity();
        setUserIdentity(newUserIdentity?.sub || null);

        // ensure we have a token
        const newToken = await authN?.getToken();
        setToken(newToken || null);
        if (newToken === null) {
          throw new Error('Auth token not set');
        }
      } catch (err) {
        // output any errors here so we have some feedback
        // eslint-disable-next-line no-console
        console.error(err);
      }
      setIsLoading(false);
    }
    if (!isLocal()) {
      initAuth();
    } else {
      setToken(getDevToken() || 'test');
      setUserIdentity({
        ID: 'lordmockington@seek.com.au',
        email: 'lordmockington@seek.com.au',
        email_verified: true,
        country: 'AU',
        brand: 'seek',
        experience: 'hirer',
      });
      setIsLoading(false);
    }
  }, [authN]);

  const getToken = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const newToken = await authN?.getToken();
    setToken(newToken || null);
    setIsLoading(false);
    return newToken || null;
  }, [authN]);

  const getUserIdentity = useCallback(async () => {
    let newUserIdentity = null;
    setIsLoading(true);
    setError(null);
    try {
      const result = await authN?.getIdentity();
      newUserIdentity = result?.sub || null;
    } catch (err) {
      // output any errors here so we have some feedback
      // eslint-disable-next-line no-console
      console.error(err);
      setError((err as Error).message);
    }
    setUserIdentity(newUserIdentity);
    setIsLoading(false);
    return newUserIdentity;
  }, [authN]);

  const login = useCallback(() => {
    const returnUri = `${window.location.pathname}${window.location.search}`;
    if (isLocal()) {
      window.location.assign(
        `${window.location.origin}/oauth/login?returnPath=${encodeURIComponent(
          returnUri,
        )}`,
      );
      return;
    }
    window.location.assign(
      `${window.location.origin}/SignIn?ReturnUrl=${encodeURIComponent(
        returnUri,
      )}`,
    );
  }, []);

  const logout = useCallback(() => {
    if (isLocal()) {
      window.location.assign(`${window.location.origin}/oauth/logout`);
      return;
    }
    window.location.assign(`${window.location.origin}/Home/SignOut`);
  }, []);

  const value = {
    getToken,
    getUserIdentity,
    error,
    isAuthenticated,
    isLoading,
    isLoadingRedirect: isLoadingRedirect.current,
    isSeekStaff,
    login,
    logout,
    token,
    userIdentity,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
