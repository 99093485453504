import { siteNames } from '@seek/melways-sites';

import { FEATURE_BENCHMARKS } from '../constants';
import { incrementalRolloutStrategy } from '../strategies';
import type { FeatureConfig, SiteSpecificFeatureConfigValue } from '../types';
import { getSiteEnvConfig } from '../utils/getSiteEnvConfig';

export const benchmarksFeature: FeatureConfig<SiteSpecificFeatureConfigValue> =
  {
    name: FEATURE_BENCHMARKS,
    value: {
      development: {
        [siteNames.employer.seek.au]: { enabled: true },
        [siteNames.employer.seek.nz]: { enabled: true },
        [siteNames.employer.seek.hk]: { enabled: true },
        [siteNames.employer.seek.th]: { enabled: true },
        [siteNames.employer.seek.sg]: { enabled: true },
        [siteNames.employer.seek.my]: { enabled: true },
        [siteNames.employer.seek.id]: { enabled: true },
        [siteNames.employer.seek.ph]: { enabled: true },
      },
      staging: {
        [siteNames.employer.seek.au]: { enabled: true },
        [siteNames.employer.seek.nz]: { enabled: true },
        [siteNames.employer.seek.hk]: { enabled: true },
        [siteNames.employer.seek.th]: { enabled: true },
        [siteNames.employer.seek.sg]: { enabled: true },
        [siteNames.employer.seek.my]: { enabled: true },
        [siteNames.employer.seek.id]: { enabled: true },
        [siteNames.employer.seek.ph]: { enabled: true },
      },
      production: {
        [siteNames.employer.seek.au]: { enabled: true },
        [siteNames.employer.seek.nz]: { enabled: true },
        [siteNames.employer.seek.hk]: { enabled: true },
        [siteNames.employer.seek.th]: { enabled: true },
        [siteNames.employer.seek.sg]: { enabled: true },
        [siteNames.employer.seek.my]: { enabled: true },
        [siteNames.employer.seek.id]: { enabled: true },
        [siteNames.employer.seek.ph]: { enabled: true },
      },
    },
    toFeature({ advertiserId, env }) {
      return {
        name: this.name,
        enabled: incrementalRolloutStrategy(
          advertiserId!,
          getSiteEnvConfig(this.value!, env),
        ),
      };
    },
  };
