
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IuC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4guC5ieC4reC4oeC4ueC4peC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IuC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C5geC4geC5ieC5hOC4giDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IuC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4guC5ieC4reC4oeC4ueC4peC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IuC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C5geC4geC5ieC5hOC4giDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IkRhdGEgaW5pIHRpZGFrIGRhcGF0IGRpdGFtcGlsa2FuIHNhYXQgaW5pIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBwZXJpa3NhIGxhZ2kgbmFudGkuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IkRhdGEgaW5pIHRpZGFrIGRhcGF0IGRpdGFtcGlsa2FuIHNhYXQgaW5pIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBwZXJpa3NhIGxhZ2kgbmFudGkuIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IuC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4guC5ieC4reC4oeC4ueC4peC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IuC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C5geC4geC5ieC5hOC4giDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IuC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4guC5ieC4reC4oeC4ueC4peC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IuC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C5geC4geC5ieC5hOC4giDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IkRhdGEgaW5pIHRpZGFrIGRhcGF0IGRpdGFtcGlsa2FuIHNhYXQgaW5pIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBwZXJpa3NhIGxhZ2kgbmFudGkuIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IkRhdGEgaW5pIHRpZGFrIGRhcGF0IGRpdGFtcGlsa2FuIHNhYXQgaW5pIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBwZXJpa3NhIGxhZ2kgbmFudGkuIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5ieKAmeG5ryDFoeG4qcO2w7bDtsW1IOG5r+G4qcOsw6zDrMWhIMaMxIPEg8SD4bmvxIPEg8SDIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbVdIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5/Eg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5ieKAmeG5ryDFoeG4qcO2w7bDtsW1IOG5r+G4qcOsw6zDrMWhIMaMxIPEg8SD4bmvxIPEg8SDIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbVdIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5/Eg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    