import type { Environment } from '@seek/hirer-graphql-client/lib-types/types';

import { isLocal } from '../config';

export const trackerEnvironmentFromEnvironment = (environment: Environment) => {
  if (isLocal()) {
    return 'dev';
  }
  if (environment === 'production') {
    return 'prod';
  }
  return 'qa';
};
