import { Stack } from 'braid-design-system';
import { useState } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { RoundedCard } from '../../components/RoundedCard/RoundedCard';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { FEATURE_BENCHMARKS, useFeatureToggleData } from '../../featureToggle';
import { AdRating } from '../../widgets/AdRating/AdRating';
import { AdRatingToolbar } from '../../widgets/AdRatingToolbar/AdRatingToolbar';

export const AdRatingSection = () => {
  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );
  const [hasToolbarError, setHasToolbarError] = useState(false);
  const [hasAdRatingError, setHasAdRatingError] = useState(false);

  const handleToolbarError = () => {
    setHasToolbarError(true);
  };

  const handleAdRatingError = () => {
    setHasAdRatingError(true);
  };

  return (
    <RoundedCard paddingBottom={!hasAdRatingError ? 'xlarge' : undefined}>
      <Stack space="xlarge">
        {!hasToolbarError && (
          <ErrorBoundary fallback={null} onError={handleToolbarError}>
            <AdRatingToolbar onQueryError={handleToolbarError} />
          </ErrorBoundary>
        )}
        {(hasToolbarError || hasAdRatingError) && <WidgetError />}
        {isBenchmarksFeatureEnabled && !hasAdRatingError && (
          <ErrorBoundary fallback={null} onError={handleAdRatingError}>
            <AdRating onQueryError={handleAdRatingError} />
          </ErrorBoundary>
        )}
      </Stack>
    </RoundedCard>
  );
};
