import { makeLinkComponent } from 'braid-design-system';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Link = makeLinkComponent(({ href, ...restProps }, ref) =>
  href.startsWith('/analytics') ? (
    <ReactRouterLink ref={ref} to={href} {...restProps} />
  ) : (
    <a ref={ref} href={href} {...restProps} />
  ),
);
