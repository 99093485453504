import { Box } from 'braid-design-system';

import { AdPerformancePageFrame } from '../../components/AdPerformancePageFrame/AdPerformancePageFrame';
import { PageLoadError } from '../../components/PageLoadError/PageLoadError';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';

export interface AdPerfromanceReportFallbackProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  children?: ReactNodeNoStrings;
}

export const AdPerformanceReportFallback = ({
  adCentreAccountInfo,
  children = <PageLoadError />,
}: AdPerfromanceReportFallbackProps) => (
  <AdPerformancePageFrame adCentreAccountInfo={adCentreAccountInfo}>
    <Box marginX={['none', 'large', 'large']}>{children}</Box>
  </AdPerformancePageFrame>
);
