import {
  Box,
  Column,
  Columns,
  Divider,
  IconChevron,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
} from 'braid-design-system';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAdCentreAccountInfo } from '../../hooks/useAdCentreAccountInfo';
import { useAuth } from '../../hooks/useAuth';
import { Logo } from '../Logo/Logo';

import * as styles from './seekStaffHeader.css';

// hardcoded background color to match adv-header-footer
const headerBgColor = '#184da8';

export interface SeekStaffHeaderProps {
  accountNumber: string;
  advertiserId: string;
  advertiserName: string;
}

export const SeekStaffHeader = ({
  accountNumber,
  advertiserId,
  advertiserName,
}: SeekStaffHeaderProps) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, login, logout, token } = useAuth();

  const { data: adCentreAccountInfo } = useAdCentreAccountInfo(token);

  const fullName = useMemo(
    () =>
      adCentreAccountInfo
        ? `${adCentreAccountInfo?.headerFooterContext?.user?.name}`
        : '',
    [adCentreAccountInfo],
  );

  const onShouldCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const onClickEvent = (e: MouseEvent) => {
      if (
        modalRef &&
        modalRef.current &&
        !modalRef.current.contains(e.target as HTMLDivElement) &&
        triggerRef &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target as HTMLDivElement)
      ) {
        onShouldCloseModal();
      }
    };
    document.addEventListener('click', onClickEvent, true);
    return () => document.removeEventListener('click', onClickEvent, true);
  }, [onShouldCloseModal]);

  const logoutClickHandler = () => {
    logout();
  };

  const loginClickHandler = () => {
    if (!isAuthenticated) {
      login();
    }
  };

  const onViewMenuClicked = () => {
    setIsOpen(!isOpen);
  };

  const advertiserText = useMemo(() => {
    if (accountNumber.length > 0) {
      return `${advertiserName} (Account Number: ${accountNumber} | SEEK ID: ${advertiserId})`;
    }
    return advertiserName;
  }, [advertiserId, advertiserName, accountNumber]);

  return (
    <Stack space="none">
      <Box
        background="brandAccent"
        paddingLeft={['small', 'xlarge']}
        paddingRight={['small', 'xlarge']}
        paddingTop="medium"
        paddingBottom="medium"
      >
        <Text align="center" size="small">
          {'Viewing as a SEEK Staff member — '}
          {advertiserId.length === 0 && 'Staff only report'}
          {advertiserId.length > 0 && (
            <TextLink
              weight="weak"
              href={`https://smartr.myseek.xyz/hirer-impersonate?id=${advertiserId}`}
              target="_blank"
            >
              Switch to Customer View
            </TextLink>
          )}
        </Text>
      </Box>
      <Box
        background="brand"
        data={{ id: 'ui-seek-staff-header' }}
        style={{ background: headerBgColor, minHeight: '70px' }}
        paddingLeft={['small', 'xlarge']}
        paddingRight={['small', 'xlarge']}
        paddingTop="medium"
        paddingBottom="medium"
      >
        <Box display="flex" alignItems="center">
          <Logo />
          <Box marginLeft="xsmall" style={{ flex: 1 }} />
          <Box position="relative">
            <Box
              component="button"
              cursor="pointer"
              padding="medium"
              ref={triggerRef}
              onClick={onViewMenuClicked}
            >
              <Columns space="small">
                <Column>
                  <Text maxLines={1}>
                    <Box component="span">{advertiserText}</Box>
                  </Text>
                </Column>
                <Column width="content">
                  <Text>
                    <Box component="span">
                      <IconChevron
                        direction={isOpen ? 'up' : 'down'}
                        alignY="lowercase"
                      />
                    </Box>
                  </Text>
                </Column>
              </Columns>
            </Box>
            {isOpen && (
              <Box
                background="surface"
                boxShadow="medium"
                borderRadius="standard"
                className={styles.menu}
                display="flex"
                flexDirection="column"
                position="absolute"
                width="full"
                right={0}
                zIndex="dropdown"
                ref={modalRef}
              >
                <Box paddingY="medium" paddingX="small">
                  <Stack align="center" space="medium">
                    <Box
                      borderRadius="full"
                      component="img"
                      src={`https://robohash.org/${fullName}.png?set=set5`}
                      style={{
                        background: headerBgColor,
                        height: '80px',
                        width: '80px',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Stack align="center" space="small">
                      <Text>{fullName}</Text>
                      <Text tone="secondary">SEEK</Text>
                    </Stack>
                  </Stack>
                </Box>
                <Divider />
                <Box paddingY="medium" paddingX="small">
                  <Text align="right">
                    <TextLinkButton
                      hitArea="large"
                      onClick={
                        isAuthenticated ? logoutClickHandler : loginClickHandler
                      }
                    >
                      {isAuthenticated ? 'Logout' : 'Login'}
                    </TextLinkButton>
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
