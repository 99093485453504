import type {
  ErrorLogger,
  GraphQLErrorLogger,
} from '@seek/hirer-graphql-client/lib-types/types';

import {
  GraphClientGraphQLError,
  GraphClientRequestError,
} from '../../utils/error';
import type { Logger } from '../../utils/logger/logger';

const mapRequestErrorLogger =
  (logger: Logger): ErrorLogger =>
  (thrownError, operationName, metadata) => {
    const error =
      thrownError instanceof Error ? thrownError : new Error('Unknown error');
    logger.error(new GraphClientRequestError(error), {
      ...metadata,
      operationName,
      activity: `ApolloClient | Error | ${operationName}`,
    });
  };

const mapGraphQLErrorLogger =
  (logger: Logger): GraphQLErrorLogger =>
  (error, operationName, metadata) => {
    logger.error(new GraphClientGraphQLError(error), {
      ...metadata,
      operationName,
      activity: `ApolloClient | GraphQLError | ${operationName}`,
    });
  };

export { mapGraphQLErrorLogger, mapRequestErrorLogger };
