import { Box } from 'braid-design-system';
import classNames from 'classnames';
import type { ReactNode, ComponentProps } from 'react';

import * as styles from './roundedCard.css';

type BoxProps = ComponentProps<typeof Box>;

export interface RoundedCardProps extends BoxProps {
  children: ReactNode;
  roundBottomOnly?: boolean;
  roundTopOnly?: boolean;
}

export const RoundedCard = ({
  children,
  roundBottomOnly,
  roundTopOnly,
  ...otherProps
}: RoundedCardProps) => (
  <Box
    background="surface"
    padding="large"
    position="relative"
    className={classNames({
      [styles.roundedCard]: !roundBottomOnly && !roundTopOnly,
      [styles.roundBottom]: roundBottomOnly,
      [styles.roundTop]: roundTopOnly,
    })}
    {...otherProps}
  >
    {children}
  </Box>
);
