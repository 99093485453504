import { Box } from 'braid-design-system';
import type React from 'react';

import { isTest } from '../../config';
import { useAuth } from '../../hooks/useAuth';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreHeader } from '../AdCentreHeader/AdCentreHeader';
import { SeekStaffHeader } from '../SeekStaffHeader/SeekStaffHeader';

// hardcoded background color to match adv-header-footer
const headerBgColor = '#184da8';

const HeaderSkeleton = () => (
  <Box
    background="brand"
    style={{ background: headerBgColor, minHeight: '70px' }}
    paddingLeft={['small', 'xlarge']}
    paddingRight={['small', 'xlarge']}
    paddingTop="medium"
    paddingBottom="medium"
  />
);

export interface HeaderProps {
  activeTabId?: 'analytics';
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  hirerId: string;
}

export const Header = ({
  activeTabId = 'analytics',
  adCentreAccountInfo,
  hirerId,
}: HeaderProps) => {
  const {
    // error: authenticationError,
    isLoading: isLoadingAuth,
    isSeekStaff,
  } = useAuth();

  if (isLoadingAuth) {
    return <HeaderSkeleton />;
  }

  const accountNumber =
    adCentreAccountInfo?.headerFooterContext.advertiser?.billingId || '';
  const advertiserName =
    adCentreAccountInfo?.headerFooterContext.advertiser?.name || 'SEEK';

  if (isTest()) {
    return (
      <SeekStaffHeader
        accountNumber={accountNumber}
        advertiserId={hirerId}
        advertiserName={advertiserName}
      />
    );
  }

  return (
    <AdCentreHeader
      activeTabId={activeTabId}
      adCentreAccountInfo={adCentreAccountInfo}
      isSeekStaff={isSeekStaff}
      accountNumber={accountNumber}
      advertiserId={hirerId}
      advertiserName={advertiserName}
    />
  );
};
