import { Box, ContentBlock, Heading, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

export interface PageErrorProps {
  icon: JSX.Element;
  title: ReactNode;
  description: ReactNode;
}

export const PageError = ({ icon, title, description }: PageErrorProps) => (
  <Box background="surface" padding="large">
    <ContentBlock width="small">
      <Stack space="medium" align="center">
        {icon}
        <Heading level="2" align="center">
          {title}
        </Heading>
        <Text tone="secondary" align="center">
          {description}
        </Text>
      </Stack>
    </ContentBlock>
  </Box>
);
