import { useTranslations } from '@vocab/react';

import { JigsawIcon } from '../../assets/icons/JigsawIcon/JigsawIcon';
import { PageError } from '../PageError/PageError';

import translations from './.vocab';

export const PageLoadError = () => {
  const { t } = useTranslations(translations);

  return (
    <PageError
      icon={<JigsawIcon width="115" height="101" />}
      title={t('We can’t show this data right now')}
      description={t(
        'We’re working on getting this back up. Refresh the page or check back later.',
      )}
    />
  );
};
