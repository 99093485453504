import {
  Heading,
  Columns,
  Column,
  Box,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import type { ComponentProps } from 'react';

type BoxProps = ComponentProps<typeof Box>;

export interface SecondarySectionProps extends BoxProps {
  icon: ReactNodeNoStrings;
  direction?: 'row' | 'column';
  subTitleComponent?: ReactNodeNoStrings;
  subTitleText?: string;
  titleComponent?: ReactNodeNoStrings;
  titleText?: string;
  tailComponent?: ReactNodeNoStrings;
}

export const SecondarySection = ({
  children,
  icon,
  subTitleComponent,
  subTitleText,
  titleComponent,
  titleText,
  tailComponent,
  direction = 'row',
  ...otherProps
}: SecondarySectionProps) => (
  <Box
    data={{ id: 'primitive-secondary-section' }}
    style={direction === 'column' ? { height: '100%' } : {}}
    {...otherProps}
  >
    <Box
      background="surface"
      position="relative"
      borderRadius="standard"
      style={direction === 'column' ? { height: '100%' } : {}}
    >
      {direction === 'row' ? (
        <Columns alignY="center" space="gutter">
          <Column width="content">
            <Box style={{ width: '48px' }}>{icon}</Box>
          </Column>
          <Column>
            <Box style={{ maxWidth: '620px' }}>
              <Stack space="medium">
                <Heading level="3">{titleText}</Heading>
                {subTitleText && <Text>{subTitleText}</Text>}
                {titleComponent}
                {subTitleComponent}
              </Stack>
            </Box>
          </Column>
          {tailComponent ? (
            <Column width="content">{tailComponent}</Column>
          ) : null}
        </Columns>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          height="full"
          className="secondary-section"
          style={{ overflowY: 'auto' }}
        >
          <Box style={{ width: '48px' }}>{icon}</Box>
          <Box width="full" flexGrow={1}>
            <Stack space="medium">
              <Heading level="3">{titleText}</Heading>
              {subTitleText && <Text>{subTitleText}</Text>}
              {titleComponent}
              {subTitleComponent}
            </Stack>
          </Box>
          <Inline space="none">{tailComponent}</Inline>
        </Box>
      )}
    </Box>
    <Box style={{ height: '2px' }} />
    {children}
  </Box>
);
