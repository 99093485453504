import { getLocale } from '../../config';

export const formatPercentage = (
  percentage: number,
  decimals: 0 | 1 | 2,
  locale = getLocale(),
): string =>
  new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(percentage / 100);
