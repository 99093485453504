import { Box, Columns, Column } from 'braid-design-system';
import { useEffect } from 'react';

import useQuery from '../../hooks/useQuery';
import { PageNavigationItem } from '../PageNavigationItem/PageNavigationItem';

const UTM_CAMPAIGN_KEY = 'utm_campaign';

export interface PageNavigationItem {
  isActive: boolean;
  label: ReactNodeNoStrings;
  url: string;
}

export interface PageNavigationProps {
  items: PageNavigationItem[];
}

export const PageNavigation = ({ items }: PageNavigationProps) => {
  const query = useQuery();

  const utmCampaign = query.get(UTM_CAMPAIGN_KEY);

  useEffect(() => {
    if (utmCampaign !== null) {
      window.localStorage.setItem(UTM_CAMPAIGN_KEY, utmCampaign);
    }
  }, [utmCampaign]);

  return (
    <Box component="nav" marginLeft="small" paddingY="xsmall" background="body">
      <Columns align="right" alignY="center" space="medium">
        <Column>
          <Box component="ol">
            {items.map((item) => (
              <PageNavigationItem key={item.label} {...item} />
            ))}
          </Box>
        </Column>
      </Columns>
    </Box>
  );
};
