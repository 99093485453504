import { Stack } from 'braid-design-system';
import { useCallback, useState } from 'react';

import type { PerformancePredictionChartData } from '../../types/PerformancePrediction';

import { ApplicationPredictionChartLegend } from './ApplicationPredictionChartLegend';
import { useApplicationPredictionChartRenderer } from './useApplicationPredictionChartRenderer';

export interface PerformancePredictionChartProps {
  data: PerformancePredictionChartData;
  height: number;
  width: number;
  widthBehaviour?: 'fixed' | 'dynamic';
}

export const ApplicationPredictionChart = ({
  data,
  height,
  width: initialWidth,
  widthBehaviour,
}: PerformancePredictionChartProps) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const containerRefHandler = useCallback((node: HTMLDivElement | null) => {
    setContainerRef(node);
  }, []);
  useApplicationPredictionChartRenderer({
    containerRef,
    data,
    height,
    width: initialWidth,
    widthBehaviour,
  });

  return (
    <Stack space="xsmall">
      <div
        data-id="ui-prediction-chart-section"
        ref={containerRefHandler}
        style={{ height, width: '100%', textAlign: 'left' }}
      />
      <ApplicationPredictionChartLegend candidateLabel={data.valueLabel} />
    </Stack>
  );
};
