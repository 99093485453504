import { Box } from 'braid-design-system';
import type React from 'react';

import { WidgetError } from '../../components/WidgetError/WidgetError';

export const PerformancePredictionFallback = () => (
  <Box
    background="surface"
    borderRadius="standard"
    height="full"
    padding="gutter"
  >
    <WidgetError />
  </Box>
);
