import { useTranslations } from '@vocab/react';
import { Stack, Text, Box } from 'braid-design-system';
import type React from 'react';

import { formatNumber } from '../../utils/number';
import { Medium } from '../Medium/Medium';

import translations from './.vocab';

export interface AppCompletesTooltipContentProps {
  incompleteApps: number;
}

export const AppCompletesTooltipContent = ({
  incompleteApps,
}: AppCompletesTooltipContentProps) => {
  const { t } = useTranslations(translations);

  return (
    <Box data={{ id: 'ui-app-completes-tooltip-content' }}>
      <Stack space="medium">
        <Stack space="small">
          <Text weight="strong" size="small">
            {t("What's this number")}
          </Text>
          <Text size="small">
            {t('Number reflects apps completed', {
              Status: (children) => <Medium>{children}</Medium>,
            })}
          </Text>
        </Stack>
        <Text size="small">
          {t('Number of apps not completed', {
            incompleteApps,
            incompleteAppsText: formatNumber(incompleteApps),
            Medium: (children) => <Medium>{children}</Medium>,
          })}
        </Text>
      </Stack>
    </Box>
  );
};
