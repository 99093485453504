import { useTranslations } from '@vocab/react';
import { Box, Stack, Text, TextDropdown } from 'braid-design-system';
import { useState } from 'react';

import type {
  CandidateLabel,
  PredictionDayOption,
} from '../../types/PerformancePrediction';
import { formatNumber } from '../../utils/number';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialogNew';
import { Medium } from '../Medium/Medium';

import translations from './.vocab';

import * as styles from './predictionSection.css';

export interface PredictionProps {
  title: CandidateLabel | null;
  predictionOptions: PredictionDayOption[] | null;
}

interface DropdownOptions {
  text: string;
  prediction: number | null;
  value: string;
}

export const PredictionSection = ({
  title,
  predictionOptions,
}: PredictionProps) => {
  const { t } = useTranslations(translations);
  const [optionValue, setOptionValue] = useState(
    predictionOptions?.[0]?.text ?? '',
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options: DropdownOptions[] =
    predictionOptions?.map((option) => {
      if (option.text === 'WHEN_AD_EXPIRES') {
        return {
          text: t('When the ad expires'),
          value: option.text,
          prediction: option.prediction,
        };
      }
      const days = option.text.replace('_DAYS_FROM_NOW', '');
      return {
        text: t('X day(s) from now', { days: Number(days) }),
        value: option.text,
        prediction: option.prediction,
      };
    }) ?? [];

  const onPredictionDayChanged = (value: string) => {
    if (!isDropdownOpen) {
      trackEvent('prediction_day_pressed', {
        objectInteraction: 'apr-prediction-day',
      });
    }
    setIsDropdownOpen(false);
    setOptionValue(value);
  };

  const getValue = (value: string): number =>
    options?.find((data) => data.value === value)?.prediction ?? 0;

  return (
    <Box data={{ id: 'ui-prediction-section' }}>
      {options.length !== 0 ? (
        <Stack space="gutter">
          <DisclosureDialog
            id="prediction-tooltip"
            tooltip={
              <Text size="small">
                {t('Prediction Based On Past Performance')}
              </Text>
            }
          >
            {({ triggerProps }) => (
              <Text align="left" weight="medium">
                <span role="button" {...triggerProps}>
                  {t('Prediction')}
                </span>
              </Text>
            )}
          </DisclosureDialog>
          <Text>
            <Box display="inlineBlock" paddingRight="small">
              <TextDropdown<string>
                id="ui-prediction-section-day-selector"
                label={t('- Please select -')}
                onChange={onPredictionDayChanged}
                options={options}
                value={optionValue}
              />
              <Box className={styles.divider} />
            </Box>
            <span>
              {t(
                title === 'CANDIDATES'
                  ? 'Ad Candidates Prediction'
                  : 'Ad Apps Prediction',
                {
                  Medium: (value) => <Medium>{value}</Medium>,
                  futureApplications: getValue(optionValue),
                  futureApplicationsText: formatNumber(getValue(optionValue)),
                },
              )}
            </span>
          </Text>
        </Stack>
      ) : null}
    </Box>
  );
};
