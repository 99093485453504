import { useTranslations } from '@vocab/react';

import { Illustrations } from '../../assets/icons/Illustrations/Illustrations';
import { PageError } from '../PageError/PageError';

import translations from './.vocab';

export const AdUsageReportLoadError = () => {
  const { t } = useTranslations(translations);

  return (
    <PageError
      icon={<Illustrations />}
      title={t('We can’t show this data right now')}
      description={t(
        'We’re working on getting this back up. Refresh the page or check back later.',
      )}
    />
  );
};
