import { useTranslations } from '@vocab/react';
import { TextLink } from 'braid-design-system';

import { useConfig } from '../../App/ConfigContext';
import { NoAccessIcon } from '../../assets/icons/NoAccessIcon/NoAccessIcon';
import { PageError } from '../PageError/PageError';

import translations from './.vocab';

export const NoAccess = () => {
  const { t } = useTranslations(translations);
  const { urlResolver } = useConfig();

  return (
    <PageError
      icon={<NoAccessIcon />}
      title={t('No access page title')}
      description={t('No access page description', {
        TextLink: (children) => (
          <TextLink href={urlResolver('/contactus')} target="_blank">
            {children}
          </TextLink>
        ),
      })}
    />
  );
};
