import 'braid-design-system/reset';
import './app.css';
import { getConfig } from '@seek/online-identity';
import { VocabProvider } from '@vocab/react';

import { AuthProvider } from '../auth/AuthProvider';
import { getEnvironment, type Config } from '../config';
import { FeatureToggleProvider } from '../featureToggle';
import { region } from '../utils/locale';

import { ContextProvider } from './ContextProvider';
import { Router } from './Router';
import { useAuth } from './hooks/useAuth';

export interface AppProps {
  config: Config;
}

export const App = ({ config }: AppProps) => {
  const { userIdentity } = useAuth();
  const clientAuthConfig = getConfig('seekAnz-hirer', window.location);

  // Initialise region helper
  region.initialise(config.locale);

  return (
    <AuthProvider config={clientAuthConfig}>
      <ContextProvider config={config}>
        <FeatureToggleProvider
          environment={getEnvironment()}
          advertiserId={userIdentity?.ID}
        >
          <VocabProvider language={config.locale ?? 'en-AU'}>
            <Router />
          </VocabProvider>
        </FeatureToggleProvider>
      </ContextProvider>
    </AuthProvider>
  );
};
