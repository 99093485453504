import { gql } from '@apollo/client';

export const viewsInJobDataQuery = gql`
  query GetViewsInJobData($input: ViewsInJobSearchWidgetInput!) {
    analyticsViewsInJobSearch(input: $input) {
      adRatingConfidence
      clickConversionInPercentage
      lastUpdatedTimestampUTC
      mobileViewsInPercentage
      similarAdsInPercentage
      similarAdsAverage
      views
      hasAdRating
    }
  }
`;
