import { getSiteFromZoneAndProduct } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  Drawer,
  Loader,
  IconFilter,
  Stack,
  Text,
} from 'braid-design-system';
import { endOfDay, subDays } from 'date-fns';
import { useCallback, useContext, useState, useEffect } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { AdUsageTabs } from '../../blocks/AdUsageTabs/AdUsageTabs';
import { AdUsagePageFrame } from '../../components/AdUsagePageFrame/AdUsagePageFrame';
import { AurSearchResultsMessage } from '../../components/AurSearchResultsMessage/AurSearchResultsMessage';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { NoAccess } from '../../components/NoAccess/NoAccess';
import { NoHistoricalDataMessage } from '../../components/NoHistoricalDataMessage/NoHistoricalDataMessage';
import { NoResultMessage } from '../../components/NoResultMessage/NoResultMessage';
import { TaxMessage } from '../../components/TaxMessage/TaxMessage';
import {
  IsFilterContext,
  IsFilterContextProvider,
} from '../../context/IsFilterContext';
import {
  AdUsageFiltersProvider,
  useAdUsageFiltersContext,
} from '../../context/adUsageFilters';
import {
  LAUNCH_DATES,
  isHistoricalDataAvailable,
} from '../../featureToggle/config/historicalDataFeature';
import { useAdUsageLastUpdated } from '../../hooks/useAdUsageLastUpdated';
import { useAuth } from '../../hooks/useAuth';
import { useHidden } from '../../hooks/useHidden';
import { useSpendData } from '../../hooks/useSpendData';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import {
  createDefaultAUREventData,
  toFormatAUDate,
  trackAURPage,
  trackWidgetView,
} from '../../utils/tealiumAUREventTracker';
import { getBrandFromSiteName } from '../../utils/tealiumEventTracker';
import { ChildAccountBanner } from '../../widgets/ChildAccountBanner/ChildAccountBanner';
import { Filters } from '../../widgets/Filters/Filters';
import { FiltersErrorBoundary } from '../../widgets/Filters/FiltersErrorBoundary';
import { JobAdList } from '../../widgets/JobAdList/JobAdList';
import { JobAdListErrorBoundary } from '../../widgets/JobAdList/JobAdListErrorBoundary';

import translations from './.vocab';
import { AdUsageReportFallback } from './AdUsageReportFallback';

import * as styles from './AdUsageReport.css';

export interface AdUsageReportProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
}

export interface AdUsageSearchResultsSummaryProps {
  count: number;
  lastUpdated: string;
}

export const AdUsageReport = ({ adCentreAccountInfo }: AdUsageReportProps) => {
  const { t } = useTranslations(translations);

  const { country, language, zone } = useConfig();

  const { userIdentity } = useAuth();

  const advertiserId = window.localStorage.getItem(
    'adv-account-selection-seek-id',
  );

  // Configuration
  const isCompactLayout = useHidden([true, true, true, false]);

  const [searchResultsSummary, updateSearchResultsSummary] = useState<
    AdUsageSearchResultsSummaryProps | undefined
  >();
  const { adUsageFilters } = useAdUsageFiltersContext();
  const { startDate, endDate, datePreset } = adUsageFilters.postingDates;
  const { isFilterAdPerformance } = useContext(IsFilterContext);

  const siteName = getSiteFromZoneAndProduct(zone, 'employer');
  const brand = getBrandFromSiteName(siteName);

  useEffect(() => {
    trackAURPage(
      createDefaultAUREventData({
        adCentreAccountInfo,
        userIdentity,
        country,
        zone,
        language,
        advertiserId: advertiserId as string,
        brand,
      }),
    );
  }, [
    adCentreAccountInfo,
    userIdentity,
    country,
    zone,
    language,
    advertiserId,
    brand,
  ]);
  useEffect(
    () =>
      trackWidgetView(
        {
          filterStartDate: toFormatAUDate(startDate),
          filterEndDate: toFormatAUDate(endDate),
          filterDateRange: datePreset
            ? datePreset?.replace('Last ', '')
            : `${toFormatAUDate(startDate)} to ${toFormatAUDate(endDate)}`,
          isFilterAdCost: false,
          isFilterAdPerformance: isFilterAdPerformance as boolean,
          isFilterAdSalary: false,
          isFilterAdStatus: false,
          isFilterAdType: false,
          isFilterCategory: false,
          isFilterChildAccount: false,
          isFilterLocation: false,
          isFilterPaidEdits: false,
          isFilterReposted: false,
          isFilterSubClassification: false,
          isFilterUser: false,
          isFilterWorkType: false,
          searchResultsTotal: (searchResultsSummary?.count ?? 0).toString(),
        },
        'Filters',
      ),

    [
      startDate,
      endDate,
      datePreset,
      isFilterAdPerformance,
      searchResultsSummary,
    ],
  );

  const { data: lastUpdatedData, isLoading, error } = useAdUsageLastUpdated();
  const { data: spendData, isLoading: isLoadingSpendData } = useSpendData();

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  const openFilterDrawer = useCallback(() => {
    setFiltersDrawerOpen(true);
  }, []);

  const closeFilterDrawer = useCallback(() => {
    setFiltersDrawerOpen(false);
  }, []);

  const advertiserName =
    adCentreAccountInfo?.headerFooterContext.advertiser?.name || 'SEEK';
  const accountNumber =
    adCentreAccountInfo?.headerFooterContext.advertiser?.billingId || '';

  const hasAccess = Boolean(
    adCentreAccountInfo?.headerFooterContext?.advertiser?.permissionOptions
      ?.canViewManagerReports,
  );

  const showNoResultMessage = Boolean(
    (searchResultsSummary && !searchResultsSummary.count) ||
      (!searchResultsSummary &&
        !isLoadingSpendData &&
        typeof spendData?.totalAds === 'number' &&
        spendData?.totalAds === 0),
  );

  if (!hasAccess) {
    return (
      <AdUsageReportFallback adCentreAccountInfo={adCentreAccountInfo}>
        <NoAccess />
      </AdUsageReportFallback>
    );
  }

  if (isLoading && !lastUpdatedData) {
    return (
      <AdUsagePageFrame adCentreAccountInfo={adCentreAccountInfo}>
        <Box display="flex" justifyContent="center" padding="large">
          <Loader />
        </Box>
      </AdUsagePageFrame>
    );
  }

  if (error || !lastUpdatedData) {
    return <AdUsageReportFallback adCentreAccountInfo={adCentreAccountInfo} />;
  }

  const hasLaunchDate = LAUNCH_DATES[siteName];
  if (
    hasLaunchDate &&
    !isHistoricalDataAvailable(siteName, lastUpdatedData.lastUpdated)
  ) {
    return (
      <AdUsagePageFrame adCentreAccountInfo={adCentreAccountInfo}>
        <NoHistoricalDataMessage />
      </AdUsagePageFrame>
    );
  }

  const lastDateForFilter = endOfDay(
    subDays(new Date(lastUpdatedData.lastUpdated), 1),
  );

  return (
    <IsFilterContextProvider>
      <AdUsageFiltersProvider
        lastDateForFilter={lastDateForFilter}
        adCentreAccountInfo={adCentreAccountInfo}
      >
        <AdUsagePageFrame
          adCentreAccountInfo={adCentreAccountInfo}
          numOfAds={searchResultsSummary?.count}
        >
          <Columns space="gutter">
            {!isCompactLayout ? (
              <Column width="content">
                <Box className={styles.adUsageFiltersContainerDesktop}>
                  <FiltersErrorBoundary>
                    <Filters
                      searchResultsTotal={searchResultsSummary?.count}
                      adCentreAccountInfo={adCentreAccountInfo}
                      lastDateForFilter={lastDateForFilter}
                    />
                  </FiltersErrorBoundary>
                </Box>
              </Column>
            ) : null}
            <Column>
              <Box
                className={styles.adUsageContentOutContainer}
                paddingLeft={isCompactLayout ? 'gutter' : 'none'}
                paddingRight="gutter"
              >
                <Box className={styles.adUsageContentInnerContainer}>
                  <Stack space="large">
                    <ErrorBoundary fallback={null}>
                      <ChildAccountBanner
                        advertiserName={advertiserName}
                        accountNumber={accountNumber}
                      />
                    </ErrorBoundary>
                    <Columns space="none">
                      <Column>
                        {searchResultsSummary && (
                          <Box height="full" display="flex" alignItems="center">
                            <AurSearchResultsMessage
                              count={searchResultsSummary.count}
                              lastUpdated={searchResultsSummary.lastUpdated}
                            />
                          </Box>
                        )}
                      </Column>
                      <Column width="content">
                        {isCompactLayout && (
                          <Button
                            onClick={openFilterDrawer}
                            variant="transparent"
                            tone="neutral"
                            icon={<IconFilter />}
                          >
                            Filters
                          </Button>
                        )}
                      </Column>
                    </Columns>

                    {showNoResultMessage ? (
                      <Box background="surface">
                        <NoResultMessage
                          title={t('No ads found title')}
                          description={t('No ads found description', {
                            Stack: (children: ReactNodeNoStrings) => (
                              <Stack space="small">{children}</Stack>
                            ),
                            Text: (children: ReactNodeNoStrings) => (
                              <Text tone="secondary" align="center">
                                {children}
                              </Text>
                            ),
                          })}
                        />
                      </Box>
                    ) : (
                      <AdUsageTabs />
                    )}
                    <JobAdListErrorBoundary>
                      <JobAdList
                        updateSearchResultsSummary={updateSearchResultsSummary}
                      />
                    </JobAdListErrorBoundary>
                  </Stack>
                </Box>
                <TaxMessage />
              </Box>
            </Column>
          </Columns>
          {isCompactLayout && (
            <Box>
              <Drawer
                id="ad-usage-filters-drawer"
                title="Filters"
                open={filtersDrawerOpen}
                onClose={closeFilterDrawer}
                width="small"
              >
                <FiltersErrorBoundary>
                  <Filters
                    searchResultsTotal={searchResultsSummary?.count}
                    adCentreAccountInfo={adCentreAccountInfo}
                    lastDateForFilter={lastDateForFilter}
                  />
                </FiltersErrorBoundary>
              </Drawer>
            </Box>
          )}
        </AdUsagePageFrame>
      </AdUsageFiltersProvider>
    </IsFilterContextProvider>
  );
};
