import { Box, Loader, Stack } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationPredictionChart } from '../../components/ApplicationPredictionChart/ApplicationPredictionChart';
import { PredictionSection } from '../../components/PredictionSection/PredictionSection';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { usePerformancePrediction } from '../../hooks/usePerformancePrediction';

export interface PerformancePredictionProps {
  onQueryError?: () => void;
}

export const PerformancePrediction = ({
  onQueryError,
}: PerformancePredictionProps) => {
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();

  const {
    isLoading,
    data: chartData,
    error,
  } = usePerformancePrediction({
    jobId: jobIdParam!,
  });

  const hasQueryError = error || !chartData;

  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  if (isLoading && !chartData) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="full"
        justifyContent="center"
        padding="medium"
      >
        <Loader />
      </Box>
    );
  }

  if (hasQueryError) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        height="full"
        paddingX="gutter"
      >
        <WidgetError />
      </Box>
    );
  }

  const {
    xRange,
    events,
    predictionDate,
    yRange,
    xAxisLabels,
    valueLabel,
    predictionDayOptions,
  } = chartData;

  const isChartRendered =
    xRange &&
    events &&
    yRange &&
    xAxisLabels &&
    valueLabel &&
    predictionDayOptions;

  return (
    <Box
      data={{ id: 'ui-performance-prediction-chart' }}
      style={{ zIndex: 5 }}
      width="full"
    >
      <Box padding="large" height="full">
        <Stack space="xlarge">
          {isChartRendered ? (
            <>
              <ApplicationPredictionChart
                data={{
                  xRange,
                  events,
                  predictionDate,
                  yRange,
                  xAxisLabels,
                  valueLabel,
                }}
                height={164}
                width={514}
                widthBehaviour="dynamic"
              />
              {predictionDayOptions.length !== 0 && (
                <PredictionSection
                  title={valueLabel}
                  predictionOptions={predictionDayOptions}
                />
              )}
            </>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
};
