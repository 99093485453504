import { type ReactNode, lazy, useEffect, useState } from 'react';

import { isClient } from '../../config';
import { featureConfigs } from '../config';
import { isOverridesAllowed } from '../controller/utils';
import type { Environment, Feature } from '../types';
import {
  getLocalStorageFeatureState,
  setLocalStorageFeatureState,
} from '../utils/localStorageFeatureState';

import { FeatureToggleContext } from './FeatureToggleContext';

const FeatureToggleController = lazy(() =>
  import('../controller').then((module) => ({
    default: module.FeatureToggleController,
  })),
);

interface ProviderProps {
  environment: Environment;
  advertiserId?: string;
  children: ReactNode;
}

export const FeatureToggleProvider = ({
  environment,
  advertiserId,
  children,
}: ProviderProps) => {
  const input = {
    advertiserId,
    env: environment,
  };

  // Feature flags can be turned on/off in local storage (for dev/testing)
  const [overrides, setOverrides] = useState<Feature[]>(
    getLocalStorageFeatureState(),
  );
  // Listen to changes to update local storage with the latest value
  useEffect(() => setLocalStorageFeatureState(overrides), [overrides]);

  // Calculate the value of the features based on configuration
  const features: Feature[] = featureConfigs.map((config) =>
    config.toFeature(input),
  );

  return (
    <FeatureToggleContext.Provider
      value={{
        features,
        overrides: isOverridesAllowed() ? overrides : [],
        setOverrides,
      }}
    >
      {children}
      {isClient() && isOverridesAllowed() && <FeatureToggleController />}
    </FeatureToggleContext.Provider>
  );
};
