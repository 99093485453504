import { useQuery } from '@apollo/client';

import { headerFooterQuery } from '../queries/headerFooterQuery';

export const useHeaderFooterContext = (isAuthenticated: boolean = true) => {
  const pathname = window?.location?.pathname ?? '';
  const search = window?.location?.search ?? '';
  const { data, error, loading } = useQuery(headerFooterQuery, {
    variables: {
      input: { location: { pathname, search } },
    },
    skip: !isAuthenticated,
  });

  return {
    data: data || null,
    isLoading: loading,
    error,
  };
};
