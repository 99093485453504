import { getLocale } from '../../config';

export function percentFromNumbersWithoutDecimal({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}): string {
  const percentageFormatter = new Intl.NumberFormat(getLocale(), {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return percentageFormatter.format(numerator / denominator);
}

export function percentWithoutDecimal({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}): number {
  return Math.round((numerator / denominator) * 100);
}
