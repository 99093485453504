import { ApolloProvider } from '@apollo/client';
import { BraidProvider, ToastProvider } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import { type ReactNode, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { createCache } from '../api/hirer-api/cache';
import { createHirerApiClient } from '../api/hirer-api/hirerApi';
import { getEnvironment, type Config } from '../config';
import { ChartThemeProvider } from '../seek-charts/ChartThemeProvider';

import { ConfigContext } from './ConfigContext';
import { Link } from './Link';
import { useAuth } from './hooks/useAuth';

export interface ContextProviderProps {
  children: ReactNode;
  config: Config;
}

export const ContextProvider = ({ children, config }: ContextProviderProps) => {
  const { getToken, login } = useAuth();
  const [client, setClient] = useState<any | null>(null);

  useEffect(() => {
    setClient(
      createHirerApiClient({
        cache: createCache({ addTypename: true }),
        getAuthToken: getToken,
        renewAuthToken: getToken,
        login,
        environment: getEnvironment(),
      }),
    );
  }, [getToken, login]);

  if (client === null) {
    return null;
  }
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <BraidProvider theme={apac} linkComponent={Link}>
          <ToastProvider>
            <ChartThemeProvider>
              <ConfigContext.Provider value={config}>
                {children}
              </ConfigContext.Provider>
            </ChartThemeProvider>
          </ToastProvider>
        </BraidProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};
