
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IuC4hOC4peC4tOC4geC4l+C4teC5iOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LiB4Lil4Liy4Lii4LmA4Lib4LmH4LiZ4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQ2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQiOiLguJzguLnguYnguKvguLLguIfguLLguJnguITguKXguLTguIHguYDguJ7guLfguYjguK3guJTguLnguKvguJnguYnguLLguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJMifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IuC4hOC4peC4tOC4geC4l+C4teC5iOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LiB4Lil4Liy4Lii4LmA4Lib4LmH4LiZ4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQ2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQiOiLguJzguLnguYnguKvguLLguIfguLLguJnguITguKXguLTguIHguYDguJ7guLfguYjguK3guJTguLnguKvguJnguYnguLLguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJMifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6Ikp1bWxhaCBrbGlrIGlrbGFuIEFuZGEiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBsYW1hcmFuIHlhbmcgZGlraXJpbSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gY2FuZGlkYXRlcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBrYW5kaWRhdCIsIkNhbmRpZGF0ZSBjbGlja2VkIHRvIHZpc2l0IHlvdXIgam9iIGFkIjoiS2FuZGlkYXQgbWVuZ2VrbGlrIHVudHVrIG1lbmd1bmp1bmdpIGhhbGFtYW4gaWtsYW4gbG93b25nYW4ga2VyamEgQW5kYS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6Ikp1bWxhaCBrbGlrIGlrbGFuIEFuZGEiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBsYW1hcmFuIHlhbmcgZGlraXJpbSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gY2FuZGlkYXRlcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBrYW5kaWRhdCIsIkNhbmRpZGF0ZSBjbGlja2VkIHRvIHZpc2l0IHlvdXIgam9iIGFkIjoiS2FuZGlkYXQgbWVuZ2VrbGlrIHVudHVrIG1lbmd1bmp1bmdpIGhhbGFtYW4gaWtsYW4gbG93b25nYW4ga2VyamEgQW5kYS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IuC4hOC4peC4tOC4geC4l+C4teC5iOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LiB4Lil4Liy4Lii4LmA4Lib4LmH4LiZ4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQ2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQiOiLguJzguLnguYnguKvguLLguIfguLLguJnguITguKXguLTguIHguYDguJ7guLfguYjguK3guJTguLnguKvguJnguYnguLLguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJMifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IuC4hOC4peC4tOC4geC4l+C4teC5iOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LiB4Lil4Liy4Lii4LmA4Lib4LmH4LiZ4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQ2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQiOiLguJzguLnguYnguKvguLLguIfguLLguJnguITguKXguLTguIHguYDguJ7guLfguYjguK3guJTguLnguKvguJnguYnguLLguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJMifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6Ikp1bWxhaCBrbGlrIGlrbGFuIEFuZGEiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBsYW1hcmFuIHlhbmcgZGlraXJpbSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gY2FuZGlkYXRlcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBrYW5kaWRhdCIsIkNhbmRpZGF0ZSBjbGlja2VkIHRvIHZpc2l0IHlvdXIgam9iIGFkIjoiS2FuZGlkYXQgbWVuZ2VrbGlrIHVudHVrIG1lbmd1bmp1bmdpIGhhbGFtYW4gaWtsYW4gbG93b25nYW4ga2VyamEgQW5kYS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6Ikp1bWxhaCBrbGlrIGlrbGFuIEFuZGEiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBsYW1hcmFuIHlhbmcgZGlraXJpbSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gY2FuZGlkYXRlcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGRpa29udmVyc2kgbWVuamFkaSBrYW5kaWRhdCIsIkNhbmRpZGF0ZSBjbGlja2VkIHRvIHZpc2l0IHlvdXIgam9iIGFkIjoiS2FuZGlkYXQgbWVuZ2VrbGlrIHVudHVrIG1lbmd1bmp1bmdpIGhhbGFtYW4gaWtsYW4gbG93b25nYW4ga2VyamEgQW5kYS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IkNsaWNrcyBvbiB5b3VyIGFkIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhcHBsaWNhdGlvbiBzdGFydHMiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBjb252ZXJ0ZWQgdG8gYXBwbGljYXRpb24gc3RhcnRzIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBjYW5kaWRhdGVzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6IkEgY2FuZGlkYXRlIGNsaWNrZWQgdG8gdmlzaXQgeW91ciBqb2IgYWQgcGFnZS4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IlvDh8aaw6zDrMOsw6fEt8WhIMO2w7bDtuC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjF0iLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6Ils8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDDp8O2w7bDtuC4geC4teC5ieG5veG6veG6veG6vcWZ4bmv4bq94bq94bq9xowg4bmvw7bDtsO2IMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SDxZnhua/FoV0iLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gw6fDtsO2w7bguIHguLXguYnhub3hur3hur3hur3FmeG5r+G6veG6veG6vcaMIOG5r8O2w7bDtiDDp8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur3FoV0iLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6Ilvhuqwgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMOnxprDrMOsw6zDp8S34bq94bq94bq9xowg4bmvw7bDtsO2IOG5vcOsw6zDrMWhw6zDrMOs4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMS1w7bDtsO2w58gxIPEg8SDxowgxqXEg8SDxIPEo+G6veG6veG6vS5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGlja3Mgb24geW91ciBhZCI6IlvDh8aaw6zDrMOsw6fEt8WhIMO2w7bDtuC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjF0iLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFwcGxpY2F0aW9uIHN0YXJ0cyI6Ils8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDDp8O2w7bDtuC4geC4teC5ieG5veG6veG6veG6vcWZ4bmv4bq94bq94bq9xowg4bmvw7bDtsO2IMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SDxZnhua/FoV0iLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGNhbmRpZGF0ZXMiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gw6fDtsO2w7bguIHguLXguYnhub3hur3hur3hur3FmeG5r+G6veG6veG6vcaMIOG5r8O2w7bDtiDDp8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur3FoV0iLCJDYW5kaWRhdGUgY2xpY2tlZCB0byB2aXNpdCB5b3VyIGpvYiBhZCI6Ilvhuqwgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMOnxprDrMOsw6zDp8S34bq94bq94bq9xowg4bmvw7bDtsO2IOG5vcOsw6zDrMWhw6zDrMOs4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMS1w7bDtsO2w58gxIPEg8SDxowgxqXEg8SDxIPEo+G6veG6veG6vS5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    