import { useTranslations } from '@vocab/react';
import {
  Box,
  IconFilter,
  Inline,
  Stack,
  Button,
  IconClear,
  Heading,
} from 'braid-design-system';
import { startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import { useContext, useEffect } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { IsFilterContext } from '../../context/IsFilterContext';
import {
  type AdUsageFiltersState,
  actionTypes,
  defaultFilters,
  useAdUsageFiltersContext,
  useAdUsageQueryFilters,
  AUR_SELECTION_BILLING_ID,
  AUR_FILTERS_LOCAL_STORAGE_KEY,
} from '../../context/adUsageFilters';
import {
  useFeatureToggleData,
  FEATURE_PERFORMANCE_RATING,
} from '../../featureToggle';
import { LAUNCH_DATES } from '../../featureToggle/config/historicalDataFeature';
import { useAdUsageFilters as useAdUsageFiltersHook } from '../../hooks/useAdUsageFilters';
import { useDataPreserver } from '../../hooks/useDataPreserver';
import { useSiteLaunchDateChecker } from '../../hooks/useSiteLaunchDateChecker';
import { useStateWithLocalStorage } from '../../hooks/useStateWithLocalStorage';
import type { AdUsageFilters } from '../../types/AdUsageFiltersResponse';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { trackEvent } from '../../utils/tealiumAUREventTracker';

import translations from './.vocab';
import { AdPerformanceFilter } from './AdPerformanceFilter/AdPerformanceFilter';
import { AdTypeFilter } from './AdTypeFilter/AdTypeFilter';
import { ClassificationFilter } from './ClassificationFilter/ClassificationFilter';
import { JobTitle } from './JobTitle/JobTitle';
import { LocationFilter } from './LocationFilter/LocationFilter';
import { MoreFilters } from './MoreFilters/MoreFilters';
import { TipsToImproveAdsFilter } from './MoreFilters/TipsToImproveAdsFilter/TipsToImproveAdsFilter';
import { PostingDateFilter } from './PostingDateFilter/PostingDateFilter';
import { UserFilter } from './UserFilter/UserFilter';

export interface FiltersProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  lastDateForFilter: Date;
  searchResultsTotal: number | undefined;
}

const FiltersContainer = ({ children }: { children: ReactNodeNoStrings }) => (
  <Box
    background="surface"
    borderRadius="standard"
    paddingX="large"
    paddingTop="medium"
    paddingBottom="xlarge"
  >
    <Stack space="xlarge">{children}</Stack>
  </Box>
);

const isDefaultFilter = ({
  lastDateForFilter,
  adUsageFilters,
  withinNumDays,
  site,
}: {
  lastDateForFilter: Date;
  adUsageFilters: AdUsageFiltersState;
  withinNumDays: (day: number) => boolean;
  site: string;
}) => {
  const filters = {
    ...defaultFilters,
    postingDates: {
      startDate: withinNumDays(30)
        ? new Date(LAUNCH_DATES[site])
        : startOfDay(subDays(lastDateForFilter!, 29)),
      endDate: startOfDay(lastDateForFilter),
      reset: false,
    },
  };

  const currentFilters = {
    ...adUsageFilters,
    postingDates: {
      startDate: adUsageFilters.postingDates.startDate,
      endDate: startOfDay(adUsageFilters.postingDates.endDate!),
      reset: false,
    },
  };
  return _.isEqual(filters, currentFilters);
};

export const Filters = ({
  adCentreAccountInfo,
  searchResultsTotal,
  lastDateForFilter,
}: FiltersProps) => {
  const { language, site } = useConfig();
  const { withinNumDays, getMinPostingDate } = useSiteLaunchDateChecker(
    site,
    lastDateForFilter,
  );
  const { t } = useTranslations(translations);
  const isPerformanceRatingFeatureEnabled = useFeatureToggleData(
    FEATURE_PERFORMANCE_RATING,
    false,
  );

  const { updateAdUsageFilters, adUsageFilters } = useAdUsageFiltersContext();
  const { data, isLoading, error } = useAdUsageFiltersHook({
    filters: useAdUsageQueryFilters(),
  });

  const deferredData: AdUsageFilters = useDataPreserver<AdUsageFilters>(data!)!;
  const filtersData: AdUsageFilters = data || deferredData;
  const [currentSelectionId, setCurrentSelectionId] = useStateWithLocalStorage(
    AUR_SELECTION_BILLING_ID,
  );

  const firstDate = getMinPostingDate();

  const [filtersDataFromLocalStorage, setFiltersDataFromLocalStorage] =
    useStateWithLocalStorage(AUR_FILTERS_LOCAL_STORAGE_KEY);

  useEffect(() => {
    const parsedFiltersDataFromLocalStorage = filtersDataFromLocalStorage
      ? JSON.parse(filtersDataFromLocalStorage)
      : {};
    if (adCentreAccountInfo) {
      setCurrentSelectionId(
        adCentreAccountInfo.headerFooterContext.advertiser!.billingId,
      );

      parsedFiltersDataFromLocalStorage[
        adCentreAccountInfo.headerFooterContext.advertiser!.billingId
      ] = error ? undefined : adUsageFilters;
      setFiltersDataFromLocalStorage(
        JSON.stringify(parsedFiltersDataFromLocalStorage),
      );
    }
  }, [
    adCentreAccountInfo,
    currentSelectionId,
    adUsageFilters,
    setCurrentSelectionId,
    setFiltersDataFromLocalStorage,
    filtersDataFromLocalStorage,
    language,
    t,
    error,
  ]);

  const {
    setIsFilterLocation,
    setIsFilterAccountHierachy,
    setIsFilterAdId,
    setIsFilterAdPerformance,
    setIsFilterAdStatus,
    setIsFilterAdType,
    setIsFilterClassification,
    setIsFilterJobTitle,
    setIsFilterRepost,
    setIsFilterTipsToImproveAds,
    setIsFilterUser,
  } = useContext(IsFilterContext);

  const resetFilters = () => {
    setIsFilterLocation(false);
    setIsFilterAccountHierachy(false);
    setIsFilterAdId(false);
    setIsFilterAdPerformance(false);
    setIsFilterAdStatus(false);
    setIsFilterAdType(false);
    setIsFilterClassification(false);
    setIsFilterJobTitle(false);
    setIsFilterRepost(false);
    setIsFilterTipsToImproveAds(false);
    setIsFilterUser(false);

    trackEvent('filter_pressed', {
      filterType: 'filter_cleared',
      filterMethod: 'neither',
    });
    updateAdUsageFilters({
      type: actionTypes.RESET,
      value: {
        endDate: lastDateForFilter,
        startDate:
          LAUNCH_DATES[site] && withinNumDays(30)
            ? new Date(LAUNCH_DATES[site])
            : startOfDay(subDays(lastDateForFilter, 29)),
        datePreset: withinNumDays(30) ? null : 'Last 30 days',
      },
    });
  };

  const FiltersTitle = ({ canClearFilters = false }) => (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="spaceBetween"
    >
      <Inline space="xsmall" alignY="center">
        <IconFilter />
        <Heading level="3">{t('Filters')}</Heading>
      </Inline>
      {canClearFilters ? (
        <Inline space="small">
          <Button
            size="standard"
            variant="transparent"
            id="reset-filters-button"
            onClick={resetFilters}
            icon={<IconClear />}
          >
            {t('Clear Filters')}
          </Button>
        </Inline>
      ) : null}
    </Box>
  );

  if (error || (!isLoading && !data)) {
    return (
      <FiltersContainer>
        <Box paddingTop="small">
          <Stack space="large">
            <FiltersTitle />
            <WidgetError />
          </Stack>
        </Box>
      </FiltersContainer>
    );
  }

  return (
    <FiltersContainer>
      <Stack space="large">
        <FiltersTitle
          canClearFilters={
            !isDefaultFilter({
              lastDateForFilter,
              adUsageFilters,
              withinNumDays,
              site,
            })
          }
        />
        <PostingDateFilter
          searchResultsTotal={searchResultsTotal}
          firstDate={firstDate}
          lastDate={lastDateForFilter}
        />
        <UserFilter users={filtersData?.users || []} enableLazyLoading={true} />
        <LocationFilter locations={filtersData?.locations || []} />
        <ClassificationFilter
          classifications={filtersData?.classifications || []}
        />
        <JobTitle
          jobTitles={filtersData?.jobTitles || []}
          enableLazyLoading={true}
        />
        <AdTypeFilter
          productDisplayNames={filtersData?.productDisplayNames || []}
        />

        {isPerformanceRatingFeatureEnabled && (
          <AdPerformanceFilter
            adPerformanceTypes={filtersData?.adRatings || []}
          />
        )}

        <TipsToImproveAdsFilter tips={filtersData?.tips || []} />
      </Stack>
      <MoreFilters
        accountHierarchies={filtersData?.accountHierarchies || []}
        adIds={filtersData?.adIds || []}
        adCentreAccountInfo={adCentreAccountInfo}
        adStatus={filtersData?.adStatus || []}
        reposts={filtersData?.reposts || []}
        budgets={filtersData?.budgets || []}
        isChildAccount={Boolean(filtersData?.isChildAccount)}
      />
    </FiltersContainer>
  );
};
