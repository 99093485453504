import type { IdentitySubject } from '@seek/online-identity/lib-types/types';
import { createContext } from 'react';

export interface AuthContextInterface {
  getToken: () => Promise<string | null>;
  getUserIdentity: () => Promise<IdentitySubject | null>;
  login: () => void;
  logout: () => void;
  error: unknown | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingRedirect: boolean;
  isSeekStaff: boolean;
  token: string | null;
  userIdentity: IdentitySubject | null;
}

export const AuthContext = createContext<AuthContextInterface>({
  getToken: () => Promise.resolve(null),
  getUserIdentity: () => Promise.resolve(null),
  login: () => {},
  logout: () => {},
  error: undefined,
  isAuthenticated: false,
  isLoading: false,
  isLoadingRedirect: false,
  isSeekStaff: false,
  token: null,
  userIdentity: null,
});
