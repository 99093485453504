import { useTranslations } from '@vocab/react';
import {
  Box,
  Text,
  IconChevron,
  TextLink,
  Columns,
  Column,
} from 'braid-design-system';
import {
  useRef,
  useCallback,
  useEffect,
  createContext,
  useReducer,
  type KeyboardEvent,
} from 'react';

import { useConfig } from '../../App/ConfigContext';
import type { AnalyticsLatestJobs } from '../../gql/generated';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { JobListItem } from '../JobListItem/JobListItem';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';
import { type Action, actionTypes } from './JobTitleNav.actions';
import { normalizeKey } from './JobTitleNav.formatters';

import * as styles from './jobTitleNav.css';

const {
  MENU_TRIGGER_UP,
  MENU_ITEM_UP,
  MENU_TRIGGER_DOWN,
  MENU_ITEM_DOWN,
  MENU_ITEM_ESCAPE,
  MENU_ITEM_TAB,
  MENU_ITEM_ENTER,
  MENU_ITEM_SPACE,
  MENU_ITEM_CLICK,
  MENU_ITEM_HOVER,
  MENU_TRIGGER_ENTER,
  MENU_TRIGGER_SPACE,
  MENU_TRIGGER_CLICK,
  MENU_TRIGGER_TAB,
  MENU_TRIGGER_ESCAPE,
  BACKDROP_CLICK,
  MENU_MOUSE_LEAVE,
} = actionTypes;

function getNextIndex(
  moveAmount: 1 | -1,
  current: number | null,
  total: number,
) {
  const maxIndex = total - 1;

  if (current === null) {
    return moveAmount > 0 ? 0 : maxIndex;
  }

  const nextIndex = moveAmount + current;

  if (nextIndex > maxIndex) {
    return 0;
  }

  if (nextIndex < 0) {
    return maxIndex;
  }

  return nextIndex;
}

interface JobTitleNavValues {
  isHighlighted: boolean;
  index: number;
  dispatch: (action: Action) => void;
  focusTrigger: () => void;
}

export const JobTitleNavContext = createContext<JobTitleNavValues | null>(null);

interface State {
  open: boolean;
  highlightIndex: number;
}

export interface JobTitleNavProps {
  activeJobId?: string;
  jobTitle?: string;
  postedViaUploader?: boolean;
  latestJobs: AnalyticsLatestJobs[];
  isLoading?: boolean;
}

const CLOSED_INDEX = -1;
const initialState: State = {
  open: false,
  highlightIndex: CLOSED_INDEX,
};

export const JobTitleNav = ({
  activeJobId,
  jobTitle,
  latestJobs,
  isLoading,
}: JobTitleNavProps) => {
  const { urlResolver } = useConfig();
  const adCentreJobListUrl = urlResolver('/jobs');

  const subMenuRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslations(translations);

  const focusTrigger = () => {
    if (triggerRef && triggerRef.current) {
      triggerRef.current.focus();
    }
  };

  const [{ open, highlightIndex }, dispatch] = useReducer(
    (state: State, action: Action): State => {
      switch (action.type) {
        case MENU_TRIGGER_UP:
        case MENU_ITEM_UP: {
          return {
            ...state,
            open: true,
            highlightIndex: getNextIndex(
              -1,
              state.highlightIndex,
              latestJobs.length,
            ),
          };
        }
        case MENU_TRIGGER_DOWN:
        case MENU_ITEM_DOWN: {
          return {
            ...state,
            open: true,
            highlightIndex: getNextIndex(
              1,
              state.highlightIndex,
              latestJobs.length,
            ),
          };
        }
        case BACKDROP_CLICK:
        case MENU_TRIGGER_ESCAPE:
        case MENU_TRIGGER_TAB:
        case MENU_ITEM_ESCAPE:
        case MENU_ITEM_TAB:
        case MENU_ITEM_ENTER:
        case MENU_ITEM_SPACE:
        case MENU_ITEM_CLICK: {
          return { ...state, open: false, highlightIndex: CLOSED_INDEX };
        }
        case MENU_ITEM_HOVER: {
          return { ...state, highlightIndex: action.value };
        }
        case MENU_TRIGGER_ENTER:
        case MENU_TRIGGER_SPACE: {
          return {
            ...state,
            open: !state.open,
            highlightIndex: state.open ? CLOSED_INDEX : 0,
          };
        }
        case MENU_MOUSE_LEAVE: {
          return {
            ...state,
            highlightIndex: CLOSED_INDEX,
          };
        }
        case MENU_TRIGGER_CLICK: {
          return { ...state, open: !state.open };
        }
        default:
          return state;
      }
    },
    initialState,
  );

  const onWindowClicked = useCallback(
    (e: any) => {
      if (
        subMenuRef.current === null ||
        subMenuRef.current.contains(e.target)
      ) {
        return;
      }
      dispatch({ type: BACKDROP_CLICK });
    },
    [subMenuRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onWindowClicked);
    return () => {
      document.removeEventListener('mousedown', onWindowClicked);
    };
  }, [onWindowClicked]);

  const onViewMenuClicked = () => {
    if (!open) {
      trackEvent('view_jobs_menu_pressed', {
        objectInteraction: 'apr-view-jobs-menu',
      });
    }
    dispatch({ type: MENU_TRIGGER_CLICK });
  };

  const onTriggerKeyUp = (event: KeyboardEvent<HTMLButtonElement>) => {
    const targetKey = normalizeKey(event);

    // Space key in keyup/keydown handler in Firefox triggers a click event.
    // This means the menu never opens, by returning early for Firefox the
    // menu is opened by firing the click handler. Only trade off is the
    // first menu item is not highlighted automatically, but considering
    // space keyboard interactions are optional this is acceptable.
    //   See Firefox bug details: https://bugzilla.mozilla.org/show_bug.cgi?id=1220143
    //   See WAI-ARIA keyboard iteractions: https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-12
    //
    // Firefox useragent check taken from the `bowser` package:
    // https://github.com/lancedikson/bowser/blob/ea8d9c54271d7b52fecd507ae8b1ba495842bc68/src/parser-browsers.js#L520
    if (
      targetKey === ' ' &&
      /firefox|iceweasel|fxios/i.test(navigator.userAgent)
    ) {
      return;
    }

    const action: Record<string, Action> = {
      ArrowDown: { type: MENU_TRIGGER_DOWN },
      ArrowUp: { type: MENU_TRIGGER_UP },
      Enter: { type: MENU_TRIGGER_ENTER },
      ' ': { type: MENU_TRIGGER_SPACE },
      Escape: { type: MENU_TRIGGER_ESCAPE },
    };

    if (action[targetKey]) {
      dispatch(action[targetKey]);
    }
  };

  const onTriggerKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const targetKey = normalizeKey(event);

    if (targetKey === 'Tab') {
      dispatch({ type: MENU_ITEM_TAB });
    }

    // Prevent arrow keys scrolling the document while navigating the menu
    const isArrowPress = targetKey.indexOf('Arrow') === 0;
    // Prevent enter or space press from triggering the click handler
    const isActionKeyPress = targetKey === 'Enter' || targetKey === ' ';

    if (isArrowPress || isActionKeyPress) {
      event.preventDefault();
    }
  };

  if (
    isLoading ||
    latestJobs === undefined ||
    latestJobs === null ||
    latestJobs.length === 0
  ) {
    return (
      <Box
        boxShadow="borderNeutralLight"
        borderRadius="standard"
        className={styles.jobTitle}
        padding="medium"
        title={jobTitle}
      >
        <Text tone="link" maxLines={1}>
          <Box
            component="span"
            color="link"
            data-test-id="latest-jobs-dropdown"
          >
            {jobTitle}
          </Box>
        </Text>
      </Box>
    );
  }

  return (
    <Box position="relative" ref={subMenuRef}>
      <ZStack height="full" width="full">
        <ZStackItem isRelativeLayer>
          <Box
            boxShadow="borderNeutralLight"
            borderRadius="standard"
            className={styles.jobTitle}
            padding="medium"
          >
            <Columns space="small">
              <Column>
                <Text tone="link" maxLines={1}>
                  <Box component="span" color="link">
                    {jobTitle}
                  </Box>
                </Text>
              </Column>
              <Column width="content">
                <Text tone="link">
                  <Box component="span" color="link">
                    <IconChevron
                      direction={open ? 'up' : 'down'}
                      alignY="lowercase"
                    />
                  </Box>
                </Text>
              </Column>
            </Columns>
          </Box>
        </ZStackItem>
        <ZStackItem>
          <Box
            component="button"
            cursor="pointer"
            height="full"
            outline="none"
            type="button"
            width="full"
            title={jobTitle}
            ref={triggerRef}
            onKeyUp={onTriggerKeyUp}
            onKeyDown={onTriggerKeyDown}
            onClick={onViewMenuClicked}
          />
        </ZStackItem>
      </ZStack>
      {open && (
        <Box
          background="surface"
          boxShadow="medium"
          className={styles.menu}
          display="flex"
          flexDirection="column"
          position="absolute"
          width="full"
          left={0}
          zIndex="dropdown"
          onMouseLeave={() => {
            dispatch({ type: MENU_MOUSE_LEAVE });
            focusTrigger();
          }}
        >
          {latestJobs.map((job, index) => (
            <JobTitleNavContext.Provider
              key={index}
              value={{
                isHighlighted: index === highlightIndex,
                index,
                dispatch,
                focusTrigger,
              }}
            >
              <JobListItem
                isActive={job.jobId === `${activeJobId}`}
                jobId={job.jobId}
                jobTitle={job.jobTitle}
                key={job.jobId}
                locationLabel={
                  job.jobLocationLabel
                    ? job.jobLocationLabel
                    : t('Location will be available soon')
                }
                postDate={job.jobCreatedTimestampUTC}
                postedBy={job.userFullName || t('Unspecified user')}
              />
            </JobTitleNavContext.Provider>
          ))}
          <Box
            paddingY="medium"
            paddingX="small"
            onMouseEnter={() => {
              dispatch({ type: MENU_MOUSE_LEAVE });
              focusTrigger();
            }}
          >
            <Text>
              <TextLink href={adCentreJobListUrl} hitArea="large">
                {t('View all job ads')}
              </TextLink>
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
