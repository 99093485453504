
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h+C4muC4suC4h+C4hOC4meC4l+C4teC5iOC4quC4suC4oeC4suC4o+C4luC5gOC4q+C5h+C4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoi4Lir4Liy4LiB4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lir4Lil4LmI4Liy4LiZ4Li14LmJIOC4geC4o+C4uOC4k+C4suC5geC4iOC5ieC4h+C5geC4reC4lOC4oeC4tOC4meC4guC4reC4h+C4hOC4uOC4k+C5gOC4nuC4t+C5iOC4reC4guC4reC4quC4tOC4l+C4mOC4tOC5jOC5g+C4meC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4hyDguKvguKPguLfguK3guJXguLTguJTguJXguYjguK08VGV4dExpbms+4LmB4Lic4LiZ4LiB4Lil4Li54LiB4LiE4LmJ4Liy4Liq4Lix4Lih4Lie4Lix4LiZ4LiY4LmMPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h+C4muC4suC4h+C4hOC4meC4l+C4teC5iOC4quC4suC4oeC4suC4o+C4luC5gOC4q+C5h+C4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoi4Lir4Liy4LiB4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lir4Lil4LmI4Liy4LiZ4Li14LmJIOC4geC4o+C4uOC4k+C4suC5geC4iOC5ieC4h+C5geC4reC4lOC4oeC4tOC4meC4guC4reC4h+C4hOC4uOC4k+C5gOC4nuC4t+C5iOC4reC4guC4reC4quC4tOC4l+C4mOC4tOC5jOC5g+C4meC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4hyDguKvguKPguLfguK3guJXguLTguJTguJXguYjguK08VGV4dExpbms+4LmB4Lic4LiZ4LiB4Lil4Li54LiB4LiE4LmJ4Liy4Liq4Lix4Lih4Lie4Lix4LiZ4LiY4LmMPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiSmlrYSBBbmRhIG1lcmFzYSBzZWhhcnVzbnlhIG1lbWlsaWtpIGFrc2VzLCBtaW50YWxhaCBhZG1pbmlzdHJhdG9yIHVudHVrIG1lbWJlcmlrYW4gYWtzZXMuIEF0YXUgaHVidW5naSA8VGV4dExpbms+dGltIExheWFuYW4gUGVsYW5nZ2FuIGthbWkuPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiSmlrYSBBbmRhIG1lcmFzYSBzZWhhcnVzbnlhIG1lbWlsaWtpIGFrc2VzLCBtaW50YWxhaCBhZG1pbmlzdHJhdG9yIHVudHVrIG1lbWJlcmlrYW4gYWtzZXMuIEF0YXUgaHVidW5naSA8VGV4dExpbms+dGltIExheWFuYW4gUGVsYW5nZ2FuIGthbWkuPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h+C4muC4suC4h+C4hOC4meC4l+C4teC5iOC4quC4suC4oeC4suC4o+C4luC5gOC4q+C5h+C4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoi4Lir4Liy4LiB4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lir4Lil4LmI4Liy4LiZ4Li14LmJIOC4geC4o+C4uOC4k+C4suC5geC4iOC5ieC4h+C5geC4reC4lOC4oeC4tOC4meC4guC4reC4h+C4hOC4uOC4k+C5gOC4nuC4t+C5iOC4reC4guC4reC4quC4tOC4l+C4mOC4tOC5jOC5g+C4meC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4hyDguKvguKPguLfguK3guJXguLTguJTguJXguYjguK08VGV4dExpbms+4LmB4Lic4LiZ4LiB4Lil4Li54LiB4LiE4LmJ4Liy4Liq4Lix4Lih4Lie4Lix4LiZ4LiY4LmMPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h+C4muC4suC4h+C4hOC4meC4l+C4teC5iOC4quC4suC4oeC4suC4o+C4luC5gOC4q+C5h+C4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoi4Lir4Liy4LiB4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LmA4LiC4LmJ4Liy4LiW4Li24LiH4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lir4Lil4LmI4Liy4LiZ4Li14LmJIOC4geC4o+C4uOC4k+C4suC5geC4iOC5ieC4h+C5geC4reC4lOC4oeC4tOC4meC4guC4reC4h+C4hOC4uOC4k+C5gOC4nuC4t+C5iOC4reC4guC4reC4quC4tOC4l+C4mOC4tOC5jOC5g+C4meC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4hyDguKvguKPguLfguK3guJXguLTguJTguJXguYjguK08VGV4dExpbms+4LmB4Lic4LiZ4LiB4Lil4Li54LiB4LiE4LmJ4Liy4Liq4Lix4Lih4Lie4Lix4LiZ4LiY4LmMPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiSmlrYSBBbmRhIG1lcmFzYSBzZWhhcnVzbnlhIG1lbWlsaWtpIGFrc2VzLCBtaW50YWxhaCBhZG1pbmlzdHJhdG9yIHVudHVrIG1lbWJlcmlrYW4gYWtzZXMuIEF0YXUgaHVidW5naSA8VGV4dExpbms+dGltIExheWFuYW4gUGVsYW5nZ2FuIGthbWkuPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiSmlrYSBBbmRhIG1lcmFzYSBzZWhhcnVzbnlhIG1lbWlsaWtpIGFrc2VzLCBtaW50YWxhaCBhZG1pbmlzdHJhdG9yIHVudHVrIG1lbWJlcmlrYW4gYWtzZXMuIEF0YXUgaHVidW5naSA8VGV4dExpbms+dGltIExheWFuYW4gUGVsYW5nZ2FuIGthbWkuPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IklmIHlvdSB0aGluayB5b3Ugc2hvdWxkIGJlIG9uZSBvZiB0aGVtLCBhc2sgeW91ciBzeXN0ZW0gYWRtaW5pc3RyYXRvciB0byBnaXZlIHlvdSBhY2Nlc3MuIE9yIHJlYWNoIG91dCB0byBvdXIgPFRleHRMaW5rPkN1c3RvbWVyIFNlcnZpY2UgdGVhbS48L1RleHRMaW5rPiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IlvDluC4geC4teC5icaaw73DvcO9IMWhw7bDtsO2bcyC4bq94bq94bq9IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSDFoeG6veG6veG6veG6veG6veG6vSDhua/huKnDrMOsw6zFoSDGjMSDxIPEg+G5r8SDxIPEg10iLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IlvDj8aSIMO9w73DvcO2w7bDtseax5rHmiDhua/huKnDrMOsw6zguIHguLXguYnEtyDDvcO9w73DtsO2w7bHmseax5ogxaHhuKnDtsO2w7bHmseax5rGmsaMIMOf4bq94bq94bq9IMO2w7bDtuC4geC4teC5ieG6veG6veG6vSDDtsO2w7bGkiDhua/huKnhur3hur3hur1tzIIsIMSDxIPEg8WhxLcgw73DvcO9w7bDtsO2x5rHmseaxZkgxaHDvcO9w73FoeG5r+G6veG6veG6vW3MgiDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5icOsw6zDrMWh4bmvxZnEg8SDxIPhua/DtsO2w7bFmSDhua/DtsO2w7YgxKPDrMOsw6zhub3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaIMSDxIPEg8Onw6fhur3hur3hur3FocWhLiDDlsWZIMWZ4bq94bq94bq9xIPEg8SDw6fhuKkgw7bDtsO2x5rHmsea4bmvIOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSA8VGV4dExpbms+w4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhua/hur3hur3hur3Eg8SDxINtzIIuPC9UZXh0TGluaz5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IlvDluC4geC4teC5icaaw73DvcO9IMWhw7bDtsO2bcyC4bq94bq94bq9IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSDFoeG6veG6veG6veG6veG6veG6vSDhua/huKnDrMOsw6zFoSDGjMSDxIPEg+G5r8SDxIPEg10iLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IlvDj8aSIMO9w73DvcO2w7bDtseax5rHmiDhua/huKnDrMOsw6zguIHguLXguYnEtyDDvcO9w73DtsO2w7bHmseax5ogxaHhuKnDtsO2w7bHmseax5rGmsaMIMOf4bq94bq94bq9IMO2w7bDtuC4geC4teC5ieG6veG6veG6vSDDtsO2w7bGkiDhua/huKnhur3hur3hur1tzIIsIMSDxIPEg8WhxLcgw73DvcO9w7bDtsO2x5rHmseaxZkgxaHDvcO9w73FoeG5r+G6veG6veG6vW3MgiDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5icOsw6zDrMWh4bmvxZnEg8SDxIPhua/DtsO2w7bFmSDhua/DtsO2w7YgxKPDrMOsw6zhub3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaIMSDxIPEg8Onw6fhur3hur3hur3FocWhLiDDlsWZIMWZ4bq94bq94bq9xIPEg8SDw6fhuKkgw7bDtsO2x5rHmsea4bmvIOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSA8VGV4dExpbms+w4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhua/hur3hur3hur3Eg8SDxINtzIIuPC9UZXh0TGluaz5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    