import { useTranslations } from '@vocab/react';
import { Stack, Text, Box } from 'braid-design-system';
import type React from 'react';

import { Medium } from '../Medium/Medium';

import translations from './.vocab';

export const AppStartsTooltipContent = () => {
  const { t } = useTranslations(translations);

  return (
    <Box data={{ id: 'ui-app-starts-tooltip-content' }}>
      <Stack space="medium">
        <Stack space="small">
          <Text weight="strong" size="small">
            {t("What's this number")}
          </Text>
          <Text size="small">
            {t('Number reflects apps started', {
              Status: (children) => <Medium>{children}</Medium>,
            })}
          </Text>
        </Stack>
        <Text size="small">
          {t('Apps complete count not possible for indirect jobs')}
        </Text>
      </Stack>
    </Box>
  );
};
