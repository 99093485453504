import { gql } from '@apollo/client';

export const headerFooterQuery = gql`
  query HeaderFooterContext($input: HeaderFooterContextInput) {
    hirerOriginZone
    headerFooterContext {
      identityContext {
        act(input: $input) {
          operatorEmail
          isCsView
        }
        email
      }
      advertiser {
        billingId
        isActivationPending
        isAgency
        name
        showOnCreditHoldMessage
        switchAccountsHref
        permissionOptions {
          canCreateJobs
          canManageBrand
          canManageCompanyProfile
          canManageUsers
          canPayInvoices
          canViewInvoiceHistory
          canViewManagerReports
        }
      }
      user {
        name
        firstName
        id
      }
    }
  }
`;
