import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Strong,
  IconCaution,
  IconPersonVerified,
  Alert,
} from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';
import { HashLink } from 'react-router-hash-link';

import { AppStartsTooltipContent } from '..//AppStartsTooltipContent/AppStartsTooltipContent';
import { chartUtils } from '../../seek-charts';
import type { AdRatingScore } from '../../types/AdPerformanceTypes';
import type { AdRatingResponse } from '../../types/AdRatingResponse';
import { mapScenarioToGroupLabelParts } from '../../utils/roleTitleHelper';
import { AdRatingChart } from '../AdRatingChart/AdRatingChart';
import { AppCompletesTooltipContent } from '../AppCompletesTooltipContent/AppCompletesTooltipContent';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialogNew';
import { ElapsedTime } from '../ElapsedTime/ElapsedTime';
import { Metric } from '../Metric/Metric';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';

import * as styles from './AdRating.css';

export interface AdRatingProps extends AdRatingResponse {
  similarAdsUrl: string;
  candidateApplicationsUrl: string;
  onMarketPerformanceTooltipClick: () => void;
  onSimilarAdsScroll: (e: HTMLElement) => void;
}

export const AdRating = ({
  adRatingScore,
  adRatingConfidence,
  applications,
  incompleteApplications,
  isLinkOutAd,
  applicationsBenchmarkHigh,
  applicationsBenchmarkLow,
  normalisedRoleTitle,
  benchmarkLocationLabel,
  jobCreatedTimestampUTC,
  lastUpdatedTimestampUTC,
  benchmarkScenario,
  classificationName,
  subClassificationName,
  similarAdsUrl,
  candidateApplicationsUrl,
  benchmarkLocationCountry,
  onMarketPerformanceTooltipClick,
  onSimilarAdsScroll,
}: AdRatingProps) => {
  const { t } = useTranslations(translations);

  const displayAdRating =
    adRatingScore !== null &&
    applicationsBenchmarkHigh !== null &&
    applicationsBenchmarkLow !== null &&
    benchmarkScenario !== null &&
    benchmarkScenario !== 'COUNTRY';

  const benchmarkGroupLabelParts = mapScenarioToGroupLabelParts({
    normalisedRoleTitle,
    benchmarkScenario,
    classificationName,
    subClassificationName,
    benchmarkLocationCountry,
  });

  const hasBenchmarkGroupLabel = benchmarkGroupLabelParts !== null;

  const daysLive = chartUtils.dateFormatter.daysAgoFromString(
    jobCreatedTimestampUTC,
  );

  const isAdRatingConfidenceLow = adRatingConfidence === 'LOW';

  const showMarketPerformance = Boolean(adRatingScore);
  const showMarketPerformanceUnavailable =
    !showMarketPerformance && daysLive > 4;
  const showAdRatingComingSoon =
    !showMarketPerformance && !showMarketPerformanceUnavailable;

  const renderAlertMessages = () => {
    const title = showAdRatingComingSoon
      ? 'Market performance rating available soon'
      : 'Market performance rating unavailable';
    const message = showAdRatingComingSoon
      ? 'Market performance rating available soon message'
      : 'Market performance rating unavailable message';
    return (
      <Alert tone="info">
        <Text>
          <Strong>{t(title)} </Strong>
          {t(message)}
        </Text>
      </Alert>
    );
  };

  const renderRatingHighlight = (rating: AdRatingScore, label: string) => {
    let ratingProps: ComponentProps<typeof Box> = {
      paddingX: 'xxsmall',
      display: 'inline',
    };

    if (rating === 'HIGH') {
      ratingProps = {
        ...ratingProps,
        background: 'positiveLight',
        className: styles.positiveTextStyle,
      };
    } else if (rating === 'NORMAL') {
      ratingProps = {
        ...ratingProps,
        background: 'infoLight',
        className: styles.normalTextStyle,
      };
    } else if (rating === 'LOW') {
      ratingProps = {
        ...ratingProps,
        background: 'criticalLight',
        className: styles.negativeTextStyle,
      };
    }

    return <Box {...ratingProps}>{label}</Box>;
  };

  return (
    <Stack space="large">
      <ZStack height={displayAdRating ? 171 : 'full'} width="full">
        {displayAdRating ? (
          <>
            <ZStackItem alignY="bottom">
              <AdRatingChart
                data={{
                  adRating: adRatingScore as AdRatingScore,
                  normalMax: applicationsBenchmarkHigh,
                  normalMin: applicationsBenchmarkLow,
                }}
              />
            </ZStackItem>
            <ZStackItem align="center" alignY="bottom">
              <Box
                data={{ id: 'ad-rating-widget' }}
                className={styles.labelOverlay}
                marginBottom="xsmall"
              >
                <Stack align="center" space="small">
                  <Metric
                    align="center"
                    metricLabelDataId="ui-rating-chart-metric"
                    metric={applications}
                    isCandidate={!isLinkOutAd}
                    metricSize="large"
                    weight="strong"
                    url={candidateApplicationsUrl}
                  />
                  <DisclosureDialog
                    id="market-performance-tooltip"
                    tooltip={
                      <Stack space="large">
                        {isLinkOutAd ? (
                          <AppStartsTooltipContent />
                        ) : (
                          <AppCompletesTooltipContent
                            incompleteApps={incompleteApplications}
                          />
                        )}
                        <Stack space="small">
                          <Text weight="strong" size="small">
                            {t('Why is number different to other SEEK pages')}
                          </Text>
                          <Text size="small">
                            {t('Number on this page may be outdated', {
                              ElapsedTime: () => (
                                <ElapsedTime
                                  pastTime={lastUpdatedTimestampUTC}
                                />
                              ),
                            })}
                          </Text>
                        </Stack>
                      </Stack>
                    }
                  >
                    {({ triggerProps }) => (
                      <Text align="center" tone="secondary">
                        <span role="button" {...triggerProps}>
                          {isLinkOutAd
                            ? t('Application start(s)', { count: applications })
                            : t('Candidate(s)', { count: applications })}
                        </span>
                      </Text>
                    )}
                  </DisclosureDialog>
                </Stack>
              </Box>
            </ZStackItem>
          </>
        ) : (
          renderAlertMessages()
        )}
      </ZStack>
      {displayAdRating && (
        <Stack space="gutter" align="center">
          <Heading align="center" level="4" weight="weak">
            {hasBenchmarkGroupLabel && benchmarkLocationLabel
              ? t(
                  `${adRatingScore} number of ${
                    isLinkOutAd ? 'application starts' : 'candidates'
                  } compared to benchmark ${
                    benchmarkGroupLabelParts.type
                  } group`,
                  {
                    Break: () => <br className={styles.breakStyle} />,
                    Span: (text) => <span>{text}</span>,
                    Highlight: (label) =>
                      renderRatingHighlight(adRatingScore, label[0] as string),
                    Strong: (text) => <Strong>{text}</Strong>,
                    benchmarkGroupLabel: benchmarkGroupLabelParts.groupLabel,
                    benchmarkLocationLabel: benchmarkGroupLabelParts.isRegional
                      ? t('Regional {benchmarkLocationLabel}', {
                          benchmarkLocationLabel,
                        })
                      : benchmarkLocationLabel,
                  },
                )
              : t(
                  `${adRatingScore} number of ${
                    isLinkOutAd ? 'application starts' : 'candidates'
                  } compared to similar ads`,
                  {
                    Break: () => <br className={styles.breakStyle} />,
                    Span: (text) => <span>{text}</span>,
                    Highlight: (label) =>
                      renderRatingHighlight(adRatingScore, label[0] as string),
                  },
                )}
          </Heading>
          <Box
            background="body"
            className={styles.note}
            paddingX="large"
            paddingY="gutter"
            width="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              align="left"
              tone="secondary"
              icon={
                isAdRatingConfidenceLow ? (
                  <IconCaution tone="caution" />
                ) : (
                  <IconPersonVerified tone="positive" />
                )
              }
            >
              {t(
                `Market performance based on ${
                  isAdRatingConfidenceLow ? 'broader ads' : 'similar ads'
                }`,
                {
                  Tooltip: (text: ReactNode[]) => (
                    <DisclosureDialog
                      id="similar-ads-tooltip"
                      tooltip={
                        <Stack space="medium">
                          <Text size="small">
                            {isAdRatingConfidenceLow
                              ? t('Market performance broader tooltip')
                              : t('Market performance similar tooltip')}
                          </Text>
                          <Text size="small" tone="secondary">
                            <HashLink
                              className={styles.similarAdsLink}
                              scroll={onSimilarAdsScroll}
                              to={similarAdsUrl}
                            >
                              {t('View ads')}
                            </HashLink>
                          </Text>
                        </Stack>
                      }
                    >
                      {({ triggerProps }) => (
                        <span
                          role="button"
                          {...triggerProps}
                          onClick={onMarketPerformanceTooltipClick}
                        >
                          {text}
                        </span>
                      )}
                    </DisclosureDialog>
                  ),
                },
              )}
            </Text>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
