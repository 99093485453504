import { useTranslations } from '@vocab/react';
import { IconChevron, Inline, Text, TextLink, Box } from 'braid-design-system';

import { useConfig } from '../../App/ConfigContext';

import translations from './.vocab';

export const AprBreadcrumb = () => {
  const { t } = useTranslations(translations);
  const { urlResolver } = useConfig();

  const adCentreJobListUrl = urlResolver('/jobs');
  return (
    <Box paddingY="large" paddingX={['medium', 'large', 'xlarge']}>
      <Inline alignY="bottom" space="xsmall">
        <Text>
          <TextLink
            href={adCentreJobListUrl}
            title={t('Back to your job list')}
          >
            {t('Jobs list')}
          </TextLink>
        </Text>
        <Text size="xsmall">
          <IconChevron direction="right" />
        </Text>
        <Text>
          <TextLink href="" title={t('You are here')}>
            {t('Ad performance')}
          </TextLink>
        </Text>
      </Inline>
    </Box>
  );
};
