import { useTranslations } from '@vocab/react';
import { Box, ContentBlock, Heading, Stack } from 'braid-design-system';
import type React from 'react';

import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { AprBreadcrumb } from '../AprBreadcrumb/AprBreadcrumb';
import { Header } from '../Header/Header';
import { HeaderExtension } from '../HeaderExtension/HeaderExtension';
import { SystemBanner } from '../SystemBanner/SystemBanner';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';

export interface AdPerformancePageFrameProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  children: ReactNodeNoStrings;
  hirerId?: number;
  stickyBar?: JSX.Element;
}

export const AdPerformancePageFrame = ({
  adCentreAccountInfo,
  children,
  hirerId,
  stickyBar,
}: AdPerformancePageFrameProps) => {
  const { t } = useTranslations(translations);

  const renderAprBreadcrumb = () => (
    <Box position="relative">
      <ZStack height="full" width="full">
        <ZStackItem>
          <HeaderExtension />
        </ZStackItem>
        <ZStackItem>
          <Box>
            <AprBreadcrumb />
          </Box>
        </ZStackItem>
      </ZStack>
    </Box>
  );

  return (
    <Box>
      <Header
        adCentreAccountInfo={adCentreAccountInfo}
        hirerId={`${hirerId || ''}`}
      />
      {stickyBar ?? null}
      <SystemBanner />
      <Stack space="large">
        {renderAprBreadcrumb()}
        <ContentBlock>
          <Box style={{ marginTop: 50 }}>
            <Stack space="large">
              <Box
                paddingX="large"
                marginLeft={['none', 'large', 'large']}
                style={{ position: 'absolute' }}
              >
                <Heading level="1">{t('My ad performance')}</Heading>
              </Box>
              <Box style={{ marginTop: 30 }}>{children}</Box>
            </Stack>
          </Box>
        </ContentBlock>
        <AdCentreFooter adCentreAccountInfo={adCentreAccountInfo} />
      </Stack>
    </Box>
  );
};
