
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDguYDguJvguKXguLXguYjguKLguJnguKHguLLguITguKXguLTguIHguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IuC4leC4seC4p+C4reC4ouC5iOC4suC4h+C4guC4reC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5geC4quC4lOC4h+C5g+C4meC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4meC4guC4reC4hyBTRUVLIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDguYDguJvguKXguLXguYjguKLguJnguKHguLLguITguKXguLTguIHguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IuC4leC4seC4p+C4reC4ouC5iOC4suC4h+C4guC4reC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5geC4quC4lOC4h+C5g+C4meC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4meC4guC4reC4hyBTRUVLIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBkaWtvbnZlcnNpIG1lbmphZGkganVtbGFoIGtsaWsgcGFkYSBpa2xhbiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiQ3VwbGlrYW4gaWtsYW4gQW5kYSBtdW5jdWwgZGkgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIFNFRUsuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBkaWtvbnZlcnNpIG1lbmphZGkganVtbGFoIGtsaWsgcGFkYSBpa2xhbiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiQ3VwbGlrYW4gaWtsYW4gQW5kYSBtdW5jdWwgZGkgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIFNFRUsuIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDguYDguJvguKXguLXguYjguKLguJnguKHguLLguITguKXguLTguIHguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IuC4leC4seC4p+C4reC4ouC5iOC4suC4h+C4guC4reC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5geC4quC4lOC4h+C5g+C4meC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4meC4guC4reC4hyBTRUVLIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiDguYDguJvguKXguLXguYjguKLguJnguKHguLLguITguKXguLTguIHguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IuC4leC4seC4p+C4reC4ouC5iOC4suC4h+C4guC4reC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5geC4quC4lOC4h+C5g+C4meC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4meC4guC4reC4hyBTRUVLIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBkaWtvbnZlcnNpIG1lbmphZGkganVtbGFoIGtsaWsgcGFkYSBpa2xhbiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiQ3VwbGlrYW4gaWtsYW4gQW5kYSBtdW5jdWwgZGkgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIFNFRUsuIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiBkaWtvbnZlcnNpIG1lbmphZGkganVtbGFoIGtsaWsgcGFkYSBpa2xhbiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiQ3VwbGlrYW4gaWtsYW4gQW5kYSBtdW5jdWwgZGkgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIFNFRUsuIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gY29udmVydGVkIHRvIGFkIGNsaWNrcyIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2guIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua/hur3hur3hur3GjCDhua/DtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IlvDncO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMWh4LiB4Li14LmJw6zDrMOsxqXGpeG6veG6veG6veG5ryDEg8SDxIPGpcal4bq94bq94bq9xIPEg8SDxZnhur3hur3hur3GjCDDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMWgw4vDi+G4sCDEtcO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qS5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua/hur3hur3hur3GjCDhua/DtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IlvDncO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMWh4LiB4Li14LmJw6zDrMOsxqXGpeG6veG6veG6veG5ryDEg8SDxIPGpcal4bq94bq94bq9xIPEg8SDxZnhur3hur3hur3GjCDDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMWgw4vDi+G4sCDEtcO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qS5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    