import { useTranslations } from '@vocab/react';
import { Alert, Box, Strong, Text } from 'braid-design-system';

import { useChildAccountBannerData } from '../../hooks/useChildAccountBannerData';

import translations from './.vocab';

export interface ChildAccountBannerProps {
  advertiserName: string;
  accountNumber: string;
}

export const ChildAccountBanner = ({
  advertiserName,
  accountNumber,
}: ChildAccountBannerProps) => {
  const { t } = useTranslations(translations);

  const { data, error, isLoading } = useChildAccountBannerData();

  if ((isLoading && !data) || error || !data || !data.isChild) return null;

  return (
    <Box paddingTop="large">
      <Alert tone="info">
        <Text>
          {t('Child account banner info', {
            Text: () => (
              <Strong>
                {advertiserName} ({accountNumber})
              </Strong>
            ),
          })}
        </Text>
      </Alert>
    </Box>
  );
};
