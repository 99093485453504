import { useIsNVLEnabled } from '@seek/uiv-banner';
import { BraidProvider, ToastProvider } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import type { ReactNode } from 'react';

import { ChartThemeContext } from './ChartThemeContext';
import { chartTheme } from './chartTheme';

export interface ChartThemeProviderProps {
  children: ReactNode;
}

export const ChartThemeProvider = ({ children }: ChartThemeProviderProps) => {
  const isNVLEnabled = useIsNVLEnabled(false);

  return (
    <ChartThemeContext.Provider value={chartTheme}>
      <BraidProvider theme={isNVLEnabled ? seekJobs : apac}>
        <ToastProvider>{children}</ToastProvider>
      </BraidProvider>
    </ChartThemeContext.Provider>
  );
};
