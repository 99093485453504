import { Box, Link, Text } from 'braid-design-system';
import type React from 'react';

import * as styles from './pageNavigationItem.css';

export interface PageNavigationItemProps {
  isActive?: boolean;
  label: string;
  url: string;
}

export const PageNavigationItem = ({
  isActive,
  label,
  url,
}: PageNavigationItemProps) => (
  <Box component="li" display="inlineBlock">
    <Link href={url}>
      <Box
        component="span"
        cursor="pointer"
        display="inlineBlock"
        paddingX="medium"
        paddingY="small"
        className={isActive ? styles.activeItem : styles.item}
      >
        <Text size="small">{label}</Text>
      </Box>
    </Link>
  </Box>
);
