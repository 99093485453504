import { gql } from '@apollo/client';

export const clicksOnYourAdQuery = gql`
  query GetClicksOnYourAd($input: ClicksOnYourAdWidgetInput!) {
    analyticsClicksOnYourAd(input: $input) {
      adRatingConfidence
      appConversionInPercentage
      totalClicks
      isLinkOutAd
      lastUpdatedTimestampUTC
      mobileClicksInPercentage
      similarAdsAverage
      similarAdsInPercentage
      hasAdRating
    }
  }
`;
