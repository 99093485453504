import { type ApolloError, useQuery } from '@apollo/client';

import { viewsInJobDataQuery } from '../queries/viewsInJobDataQuery';
import type { ViewsInJobSearchResponse } from '../types/ViewsInJobSearchResponse';

interface ViewsInJobSearchQueryParams {
  input: {
    jobId: string;
  };
}

export const useViewsInJobSearchData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: ViewsInJobSearchResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { data, loading, error, previousData } = useQuery<
    { analyticsViewsInJobSearch: ViewsInJobSearchResponse },
    ViewsInJobSearchQueryParams
  >(viewsInJobDataQuery, {
    skip: !jobId,
    variables: {
      input: {
        jobId,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsViewsInJobSearch,
    isLoading: loading,
    error,
  };
};
