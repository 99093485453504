import { useTranslations } from '@vocab/react';
import { Box, Page } from 'braid-design-system';

import { useConfig } from '../../App/ConfigContext';
import { AdUsageHeader } from '../../blocks/AdUsageHeader/AdUsageHeader';
import {
  FEATURE_DISCOVER_LINK,
  useFeatureToggleData,
} from '../../featureToggle';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { Header } from '../Header/Header';
import {
  PageNavigation,
  type PageNavigationItem,
} from '../PageNavigation/PageNavigation';
import { SystemBanner } from '../SystemBanner/SystemBanner';

import translations from './.vocab';

export interface AdUsagePageFrameProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  numOfAds?: number;
  children: ReactNodeNoStrings;
}

export const AdUsagePageFrame = ({
  children,
  adCentreAccountInfo,
  numOfAds,
}: AdUsagePageFrameProps) => {
  const { t } = useTranslations(translations);
  const { urlResolver, absoluteUrlResolver } = useConfig();
  const isFeatureDiscoverLinkEnabled = useFeatureToggleData(
    FEATURE_DISCOVER_LINK,
  );
  const items: PageNavigationItem[] = [
    {
      isActive: true,
      label: t('Ad Usage'),
      url: urlResolver('/analytics/ad-usage'),
    },
    {
      isActive: false,
      label: t('Talent Search Usage'),
      url: absoluteUrlResolver('/analytics/talent-search-usage'),
    },
  ];

  // Only add this Discover Link if the feature is enabled
  if (isFeatureDiscoverLinkEnabled) {
    items.push({
      isActive: false,
      label: t('Discover More'),
      url: urlResolver('/analytics/discover'),
    });
  }

  const hasAccess = Boolean(
    adCentreAccountInfo?.headerFooterContext?.advertiser?.permissionOptions
      ?.canViewManagerReports,
  );

  return (
    <Page
      footerPosition="belowFold"
      footer={<AdCentreFooter adCentreAccountInfo={adCentreAccountInfo} />}
    >
      <Header adCentreAccountInfo={adCentreAccountInfo} hirerId="" />
      <SystemBanner />
      <PageNavigation items={items} />
      <AdUsageHeader hasAccess={hasAccess} numOfAds={numOfAds} />
      {children}
      <Box id="modal-base" />
    </Page>
  );
};
