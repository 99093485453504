import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { marketInsightsTitleQuery } from '../queries/marketInsightsTitleQuery';
import type { MarketInsightsTitleResponse } from '../types/MarketInsight';

interface MarketInsightsTitleQueryParams {
  input: {
    jobId: string;
    locale: string;
  };
}

export const useMarketInsightsTitleData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: MarketInsightsTitleResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery<
    { analyticsMarketInsightsTitle: MarketInsightsTitleResponse },
    MarketInsightsTitleQueryParams
  >(marketInsightsTitleQuery, {
    skip: !jobId,
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsMarketInsightsTitle,
    isLoading: loading,
    error,
  };
};
