import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App/App';
import { isProduction, type Config, isStaging } from './config';
import { initTracker, trackerEnvironmentFromEnvironment } from './tracker';
import { createDatadogRumLogger } from './utils/datadogRum/datadogRumLogger';
import LoggerProvider from './utils/logger/logger';
import { initTealiumAURTracker } from './utils/tealiumAUREventTracker';
import { initTealiumTracker } from './utils/tealiumEventTracker';

window.onerror = function (message, url) {
  let jsFile = 'other';
  if (url && url.includes(window.location.host)) {
    if (url.match(/\/main.*\.js$/)) {
      jsFile = 'main';
    } else if (url.match(/\/vendors~main.*\.js$/)) {
      jsFile = 'vendors-main';
    }
  }
  // eslint-disable-next-line no-console
  console.error(`js-file:${jsFile}`); // TODO: Add new datadog error logger here
};

export interface ClientContext {
  config: Config;
}

async function enableMocking(config: Config) {
  // don't run msw worker
  // if is production environment
  // or if devToken is provided
  if (config.environment !== 'development' || config.devToken) {
    return;
  }

  const { worker } = await import('./msw/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

export default ({ config }: ClientContext) => {
  const rootElement = document.getElementById('app') as Element;
  const root = createRoot(rootElement);
  initTracker(trackerEnvironmentFromEnvironment(config.environment));
  initTealiumTracker();
  initTealiumAURTracker();
  const { environment, version } = config;
  if (isProduction() || isStaging()) {
    LoggerProvider.init(createDatadogRumLogger({ environment, version }));
  }

  enableMocking(config).then(() => {
    root.render(
      <StrictMode>
        <App config={config} />
      </StrictMode>,
    );
  });
};
